import {
  isAUTCustomer,
  isDEUCustomer,
  isDistributorCustomer,
  isESPCustomer,
  isEURCustomer,
  isFRACustomer,
  isGBRCustomer,
  isIRLCustomer,
  isNLDCustomer,
} from 'utils/CustomerUtils';
import { hasMigratedRobo } from 'utils/IPUtils';
import {
  hasCryptoPortfolio,
  hasEtfConfiguratorProducts,
  hasPPProducts,
  LINKS as PENSION_PRODUCTS_LINKS,
} from 'utils/PensionProducts';

// Constants
export const HAS_FIXED_DEPOSIT_ACCESS = 'HAS_FIXED_DEPOSIT_ACCESS';
export const HAS_FIXED_DEPOSIT_ACCOUNT = 'HAS_FIXED_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCESS = 'HAS_FLEX_DEPOSIT_ACCESS';
export const HAS_FLEX_DEPOSIT_ACCOUNT = 'HAS_FLEX_DEPOSIT_ACCOUNT';
export const HAS_INVESTMENT_PRODUCT_ACCESS = 'HAS_INVESTMENT_PRODUCT_ACCESS';
export const HAS_INVESTMENT_PRODUCT_ACCOUNT = 'HAS_INVESTMENT_PRODUCT_ACCOUNT';
export const HAS_NOTICE_DEPOSIT_ACCESS = 'HAS_NOTICE_DEPOSIT_ACCESS';
export const HAS_NOTICE_DEPOSIT_ACCOUNT = 'HAS_NOTICE_DEPOSIT_ACCOUNT';
export const HAS_OVERNIGHT_DEPOSIT_ACCESS = 'HAS_OVERNIGHT_DEPOSIT_ACCESS';
export const HAS_OVERNIGHT_DEPOSIT_ACCOUNT = 'HAS_OVERNIGHT_DEPOSIT_ACCOUNT';
export const LEGACY_OBS_BASE = 'https://banking.weltsparen.de/savingglobal/#';
export const MY_RAISIN_MENU_ITEM_ID = 'my-raisin';

export const EXTERNAL_LINKS = {
  GLOBAL: {
    USER_ACCOUNT: '/user-account',
    MY_DETAILS: '/my-details',
    EXEMPTION_ORDER: '/exemption-order',
    EXEMPTION_ORDER_OVERVIEW: '/exemption-order/overview',
    NOTIFICATIONS: '/notifications',
    TRANSACTIONS: '/account-transactions',
    WITHDRAWAL: '/account-withdrawal',
    LOGIN: '/login',
    INITIAL_REFERENCE_ACCOUNT: '/my-details/initial-reference-account',
    UPDATE_FINANCIAL_INFORMATION: '/my-details/financial-information',
    CDVC: '/my-data/validate',
  },
  RESOLVE_OVERDRAFT: '/account-transactions/resolve-overdraft',
  POSTBOX: {
    HOME: '/postbox/messages',
    WRITE_MESSAGE: '/postbox/messages?action=write-message',
  },
  DOCUMENTS: {
    HOME: '/documents',
  },
  IDENTIFICATION: {
    HOME: '/identification',
    PROOF_OF_ADDRESS: '/identification/upload-proof-of-address',
    UPLOAD_ID_DOCUMENT: '/identification/upload-id-document',
  },
  AUT: {
    ABOUT_RAISIN: '/ueber-weltsparen/',
    BANKS: '/bank/',
    CONTACT: '/kontakt/',
    FAQS: 'http://kundenservice.weltsparen.at/',
    FIXED_DEPOSIT: '/festgeld/',
    HOW_IT_WORKS: '/so-funktionierts/',
    INSURANCE_DEPOSIT: '/einlagensicherung/',
    MAGAZINE: '/magazin/',
    OVERNIGHT: '/tagesgeld/',
  },
  DEU: {
    ABOUT_RAISIN: '/ueber-weltsparen/',
    BANKS: '/bank/',
    CONTACT: '/kontakt/',
    ETF_CONFIGURATOR: '/investieren/configurator/',
    ETF_ROBO_ADVISOR: '/investieren/robo-advisor/',
    ETF_SAVING_PLANS: '/sparplan/etf-sparplan/',
    CRYPTO_LANDING_PAGE: '/crypto/',
    CRYPTO_COCKPIT: '/crypto/cockpit',
    FAQS: 'https://kundenservice.weltsparen.de/',
    FIXED_DEPOSIT: '/festgeld/',
    HOW_IT_WORKS: '/so-funktionierts/',
    INSURANCE_DEPOSIT: '/einlagensicherung/',
    INVESMENT: '/geldanlage/',
    MAGAZINE: '/magazin/',
    NOTICE_FEE: '/kuendigungsgeld/',
    OVERNIGHT: '/tagesgeld/',
    PENSION: '/altersvorsorge/',
    RAISIN_PENSION: 'https://www.raisin-pension.de/',
    PENSION_LOGOUT: 'https://www.weltsparen.de/investieren/etf-ruerup/logout/',
    SAVINGS: '/sparen/',
    TAX_INFORMATION: '/steuern/',
    BUSINESS: {
      BANKS: '/geschaeftskunden/bankenuebersicht/',
      INSURANCE_DEPOSIT: '/geschaeftskunden/einlagensicherung/',
      TAX_INFORMATION: '/geschaeftskunden/steuern/',
      HOW_IT_WORKS: '/geschaeftskunden/so-funktionierts/',
      ABOUT_RAISIN: '/geschaeftskunden/ueber-weltsparen/',
      EVENTS: '/veranstaltungen/',
      AWARDS: '/auszeichnungen/',
      FAQS: 'https://kundenservice.weltsparen.de/',
    },
  },
  EUR: {
    ABOUT_RAISIN: '/about-raisin/',
    BANKS: '/bank/',
    BLOG: '/blog/',
    CONTACT: '/contact/',
    DEPOSIT_GUARANTEE: '/deposit-guarantee/',
    FAQS: 'https://help.raisin.com/',
    HOW_IT_WORKS: '/how-it-works/',
    HOW_TO_REGISTER: '/quick-start/',
    WELCOME_BONUS: '/welcome-bonus/',
    INTEREST_RATE_TRACKER: '/interest-rate-tracker/',
  },
  GBR: {
    ABOUT_RAISIN: '/about-us/',
    BANKING_GUIDE: '/banking/',
    BANKS: '/bank/',
    CONTACT: '/contact/',
    DEPOSIT_GUARANTEE: '/deposit-protection/',
    FAQS: 'https://help.raisin.co.uk/',
    HOW_IT_WORKS: '/how-raisin-uk-works/',
    HOW_TO_REGISTER: '/guides/quick-start/',
    INVESTMENT_GUIDE: '/investments/',
    PENSIONS_GUIDE: '/pensions/',
    PRESS: '/newsroom/',
    SAVINGS_GUIDE: '/savings/',
    TAX_INFORMATION: '/taxes/',
    UK_GUIDES: '/guides/',
    WELCOME_BONUS: '/bonus/',
  },
  ESP: {
    ABOUT_RAISIN: '/acerca-raisin/',
    BANKS: '/bancos/',
    BLOG: '/blog/',
    CONTACT: '/contacto/',
    DEPOSIT_GUARANTEE: '/fondo-garantia-depositos/',
    FAQS: 'https://ayuda.raisin.es/',
    HOW_IT_WORKS: '/como-funciona/',
    HOW_TO_REGISTER: '/como-registrarte-en-raisin/',
    INVESTMENT_GUIDE: '/inversion/',
    PERSONAL_FINANCE_GUIDE: '#',
    RAISIN_BANK: '/raisin-bank/',
    SAVING_IN_EUROPE: '/ahorro/ahorrar-en-europa/',
    SAVINGS_GUIDE: '/ahorro/',
    TAX_INFORMATION: '/impuestos/',
    WELCOME_BONUS: '/promocion/',
  },
  IRL: {
    ABOUT_RAISIN: '/about-raisin/',
    BANKS: '/bank/',
    BLOG: '/blog/',
    CONTACT: '/contact/',
    DEPOSIT_GUARANTEE: '/deposit-guarantee/',
    FAQS: 'https://help.raisin.ie/',
    HOW_IT_WORKS: '/how-it-works/',
    HOW_TO_REGISTER: '/how-to-register/',
    TAX_INFORMATION: '/tax/',
    WELCOME_BONUS: '/welcome-bonus/',
    TAXES_GUIDE: '/taxes/',
    SAVINGS_GUIDE: '/savings/',
    BANKING_GUIDE: '/banking/',
  },
  NLD: {
    ABOUT_RAISIN: '/over-raisin/',
    BANKS: '/banken/',
    CONTACT: '/contact/',
    DEPOSIT_GUARANTEE: '/depositogarantie/',
    FAQS: 'https://klantenservice.raisin.nl/',
    HOW_IT_WORKS: '/zo-werkt-het/',
    SAVING_ABROAD: '/spaarrente/buitenland/',
    SAVING: '/sparen/',
    TAX_INFORMATION: '/belasting/',
    WELCOME_BONUS: '/welkomstbonus/',
    RAISIN_BANK: '/raisin-bank/',
    SERVICE_AND_CONTACT: '/service-en-contact/',
  },
  FRA: {
    ABOUT_US: '/a-propos/',
    DEPOSIT_GUARANTEE: '/garantie-des-depots/',
    HOW_TO_INVEST: '/comment-investir/',
    PRESS: '/presse/',
    SAVINGS_GUIDE: '/epargne/',
    WELCOME_BONUS: '/bonusnouvelleannee/',
    BANKS: '/banque/',
  },
  POL: {
    ABOUT_RAISIN: '/about-raisin/',
    BANKS: '/bank/',
    BLOG: '/blog/',
    CONTACT: '/contact/',
    DEPOSIT_GUARANTEE: '/deposit-guarantee/',
    FAQS: 'https://help.raisin.com/',
    HOW_IT_WORKS: '/how-it-works/',
    HOW_TO_REGISTER: '/quick-start/',
    WELCOME_BONUS: '/welcome-bonus/',
    INTEREST_RATE_TRACKER: '/interest-rate-tracker/',
  },
};

export const LINKS = {
  ABOUT: {
    PRIVACY: 'about/privacy',
  },
  DASHBOARD: '/Dashboard',
  INVESTMENT_PRODUCTS: {
    SAVINGS_PLAN: '/InvestmentProducts/savingsPlan/edit',
  },
  LOADING_SCREEN: '/Loading',
  LOGIN: '/Login',
  MY_INVESTMENTS: {
    ROOT: '/MyInvestments',
    COCKPIT: '/investments/wealth-management/dashboard?ref=header',
    NOTICE: '/MyInvestments/Notice',
    OVERNIGHT: '/MyInvestments/Overnight',
    TERM_DEPOSIT: '/MyInvestments/TermDeposit',
    DWM_COCKPITS: '/investments/wealth-management/dashboard?ref=header',
    DWM_LP: '/vermoegensverwaltung',
  },
  POSTBOX: {
    COMPOSE: '/Postbox/Compose/new',
    DOCUMENTS: '/Postbox/documents',
    INBOX: '/Postbox/inbox',
    SENT: '/Postbox/sent',
  },
  PRODUCTS: {
    DEFAULT: '/Products',
    EASY_ACCESS: '/Products/easyAccess',
    INVESTMENT_PRODUCTS_CONFIGURATOR_FUNDS_OVERVIEW: '/investieren/configurator/fondsuebersicht',
    INVESTMENT_PRODUCTS_CONFIGURATOR: '/investieren/configurator',
    PRIVATE_EQUITY: '/Dashboard#product-details-investmentProducts',
    INVESTMENT_PRODUCTS_PARENT: '/investieren',
    INVESTMENT_PRODUCTS_DWM: '/vermoegensverwaltung',
    NOTICE: '/Products/notice',
    OVERNIGHT: '/Products/overnight',
  },
  SETTINGS: {
    LOCK_ACCOUNT: '/BlockAccount',
    LOCK_MTAN: '/LockMTan/edit',
    MY_DATA: '/MyData',
    TAX_EXEMPTION: '/TaxExemption',
  },
  REFER_A_FRIEND: '/ReferAFriend/leads',
  SET_PASSWORD: '/SetPassword',
};

const shouldDisplayBonusMenu = (customer, shouldHideWelcomeBonus) => {
  if (isGBRCustomer(customer) || isFRACustomer(customer)) {
    return true;
  }

  return !shouldHideWelcomeBonus;
};

function shouldDisplayFindOffers(productAccess, pensionProductsData, piaProductInstances) {
  const hasFixedOrFlex =
    productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
    productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT);
  const hasOvernight = productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT);
  const hasNotice = productAccess.includes(HAS_NOTICE_DEPOSIT_ACCOUNT);
  const hasIPPortfolio = hasMigratedRobo(piaProductInstances);
  const hasPPPortfolio = hasPPProducts(pensionProductsData);
  const hasETFC = hasEtfConfiguratorProducts(pensionProductsData);
  const orderedProductsCount =
    (hasFixedOrFlex ? 1 : 0) +
    (hasOvernight ? 1 : 0) +
    (hasNotice ? 1 : 0) +
    (hasIPPortfolio ? 1 : 0) +
    (hasPPPortfolio ? 1 : 0) +
    (hasETFC ? 1 : 0);

  return orderedProductsCount > 0;
}

const ABOUT_US_SUBMENU_ITEMS = (country, customer) => [
  {
    id: 'howItWorks',
    link: customer.is_company_customer
      ? EXTERNAL_LINKS.DEU.BUSINESS.HOW_IT_WORKS
      : EXTERNAL_LINKS[country].HOW_IT_WORKS,
    shouldDisplayIt:
      isDEUCustomer(customer) ||
      isAUTCustomer(customer) ||
      isNLDCustomer(customer) ||
      isGBRCustomer(customer),
    translation: 'header.navigation.subMenu.title.howItWorks',
    external: true,
  },
  {
    id: 'about-raisin',
    link: customer.is_company_customer
      ? EXTERNAL_LINKS.DEU.BUSINESS.ABOUT_RAISIN
      : EXTERNAL_LINKS[country].ABOUT_RAISIN,
    shouldDisplayIt:
      (isDEUCustomer(customer) || isAUTCustomer(customer) || isEURCustomer(customer)) &&
      !isNLDCustomer(customer),
    translation: 'header.navigation.subMenu.title.aboutRaisin',
    external: true,
  },
  {
    id: 'events',
    link: EXTERNAL_LINKS.DEU.BUSINESS.EVENTS,
    shouldDisplayIt: customer.is_company_customer,
    translation: 'header.navigation.subMenu.title.events',
    external: true,
  },
  {
    id: 'awards',
    link: EXTERNAL_LINKS.DEU.BUSINESS.AWARDS,
    shouldDisplayIt: customer.is_company_customer,
    translation: 'header.navigation.subMenu.title.awards',
    external: true,
  },
  {
    id: 'deposit-gurantee',
    link: EXTERNAL_LINKS[country].DEPOSIT_GUARANTEE,
    shouldDisplayIt: isFRACustomer(customer) || isGBRCustomer(customer),
    translation: 'header.navigation.subMenu.title.depositGuarantee',
    external: true,
  },
  {
    id: 'banks',
    link: EXTERNAL_LINKS[country].BANKS,
    shouldDisplayIt:
      (isDEUCustomer(customer) || isAUTCustomer(customer) || isEURCustomer(customer)) &&
      !customer.is_company_customer,
    translation: 'header.navigation.subMenu.title.banks',
    external: true,
  },
  {
    id: 'interest-rate-tracker',
    link: EXTERNAL_LINKS[country].INTEREST_RATE_TRACKER,
    shouldDisplayIt:
      isEURCustomer(customer) &&
      !isESPCustomer(customer) &&
      !isFRACustomer(customer) &&
      !isIRLCustomer(customer) &&
      !isNLDCustomer(customer) &&
      !isGBRCustomer(customer),
    translation: 'header.navigation.subMenu.title.interestRateTracker',
    external: true,
  },
  {
    id: 'faqs',
    link: customer.is_company_customer
      ? EXTERNAL_LINKS.DEU.BUSINESS.FAQS
      : EXTERNAL_LINKS[country].FAQS,
    shouldDisplayIt: isDEUCustomer(customer) || isAUTCustomer(customer) || isFRACustomer(customer),
    translation: 'header.navigation.subMenu.title.faqs',
    external: true,
    newTab: true,
  },
  {
    id: 'contact',
    link: EXTERNAL_LINKS[country].CONTACT,
    shouldDisplayIt: isDEUCustomer(customer) || isAUTCustomer(customer) || isFRACustomer(customer),
    translation: 'header.navigation.subMenu.title.contact',
    external: true,
  },
  // duplicate about-raisin section added for NLD to satisfy NLD menu items order
  {
    id: 'about-raisin',
    link: EXTERNAL_LINKS[country].ABOUT_RAISIN,
    shouldDisplayIt: isNLDCustomer(customer),
    translation: 'header.navigation.subMenu.title.aboutRaisin',
    external: true,
  },
  {
    id: 'raisin-bank',
    link: EXTERNAL_LINKS[country].RAISIN_BANK,
    shouldDisplayIt: isESPCustomer(customer) || isNLDCustomer(customer),
    translation: 'header.navigation.subMenu.title.raisinBank',
    external: true,
  },
];

const getAdministrationSubMenuItemsLegacy = (customer) => [
  {
    id: 'my-data',
    link: LINKS.SETTINGS.MY_DATA,
    shouldDisplayIt: true,
    translation: 'header.navigation.menu.banking.subTitle.myData',
  },
  {
    id: 'notifications',
    link: EXTERNAL_LINKS.GLOBAL.NOTIFICATIONS,
    shouldDisplayIt: true,
    translation: 'header.navigation.menu.banking.subTitle.notifications',
    external: true,
  },
  {
    id: 'transactions',
    link: EXTERNAL_LINKS.GLOBAL.TRANSACTIONS,
    shouldDisplayIt: true,
    external: true,
    translation: 'header.navigation.menu.banking.subTitle.transactions',
  },
  {
    id: 'pay-out',
    link: EXTERNAL_LINKS.GLOBAL.WITHDRAWAL,
    shouldDisplayIt: true,
    external: true,
    translation: 'header.navigation.menu.banking.subTitle.payOut',
  },
  {
    id: 'block-account',
    link: LINKS.SETTINGS.LOCK_ACCOUNT,
    shouldDisplayIt: true,
    translation: 'header.navigation.menu.banking.subTitle.blockAccount',
  },
  {
    id: 'block-tan',
    link: LINKS.SETTINGS.LOCK_MTAN,
    shouldDisplayIt: true,
    translation: 'header.navigation.menu.banking.subTitle.blockTan',
  },
  {
    id: 'tax-exemption',
    link: LINKS.SETTINGS.TAX_EXEMPTION,
    shouldDisplayIt: customer.has_fiduciary_account && !isGBRCustomer(customer),
    translation: 'header.navigation.menu.banking.subTitle.taxExemption',
  },
];

const getAdministrationSubMenuItems = (customer) => [
  {
    id: 'viewTransactions',
    link: EXTERNAL_LINKS.GLOBAL.TRANSACTIONS,
    shouldDisplayIt: true,
    external: true,
    translation: 'header.navigation.menu.banking.subTitle.administration.viewTransactions',
  },
  {
    id: 'withdrawFunds',
    link: EXTERNAL_LINKS.GLOBAL.WITHDRAWAL,
    shouldDisplayIt: true,
    external: true,
    translation: 'header.navigation.menu.banking.subTitle.administration.withdrawFunds',
  },
  {
    id: 'loginAndSecurity',
    link: EXTERNAL_LINKS.GLOBAL.USER_ACCOUNT,
    shouldDisplayIt: true,
    translation: 'header.navigation.menu.banking.subTitle.administration.loginAndSecurity',
    external: true,
  },
  {
    id: 'myDetails',
    link: EXTERNAL_LINKS.GLOBAL.MY_DETAILS,
    shouldDisplayIt: true,
    translation: 'header.navigation.menu.banking.subTitle.administration.myDetails',
    external: true,
  },
  {
    id: 'exemptionOrder',
    link: EXTERNAL_LINKS.GLOBAL.EXEMPTION_ORDER,
    shouldDisplayIt: customer.has_fiduciary_account && !isGBRCustomer(customer),
    translation: 'header.navigation.menu.banking.subTitle.administration.exemptionOrder',
    external: true,
  },
  {
    id: 'notifications',
    link: EXTERNAL_LINKS.GLOBAL.NOTIFICATIONS,
    shouldDisplayIt: !isGBRCustomer(customer),
    translation: 'header.navigation.menu.banking.subTitle.administration.notifications',
    external: true,
  },
];

const getOnlineBankingMenu = (
  customer,
  productAccess,
  pensionProductsData,
  piaProductInstances,
  shouldShowNewAdministrationMenu,
) => [
  {
    id: 'my-investments',
    shouldDisplayIt: true,
    translation: 'header.navigation.menu.banking.title.myInvestments',
    subMenu: [
      {
        id: 'dashboard',
        link: LINKS.DASHBOARD,
        shouldDisplayIt: true,
        translation: 'header.navigation.menu.banking.subTitle.dashboard',
      },
      {
        id: 'fixed-deposit',
        link: LINKS.MY_INVESTMENTS.TERM_DEPOSIT,
        shouldDisplayIt:
          productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT),
        translation: 'header.navigation.menu.banking.subTitle.fixedDeposit',
      },
      {
        id: 'overnight-deposit',
        link: LINKS.MY_INVESTMENTS.OVERNIGHT,
        shouldDisplayIt:
          productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) &&
          (isDEUCustomer(customer) ||
            isAUTCustomer(customer) ||
            isESPCustomer(customer) ||
            isNLDCustomer(customer) ||
            isFRACustomer(customer) ||
            isIRLCustomer(customer) ||
            isGBRCustomer(customer)),
        translation: 'header.navigation.menu.banking.subTitle.overnightDeposit',
      },
      {
        id: 'notice-deposit',
        link: LINKS.MY_INVESTMENTS.NOTICE,
        shouldDisplayIt:
          productAccess.includes(HAS_NOTICE_DEPOSIT_ACCOUNT) &&
          (isDEUCustomer(customer) ||
            (isESPCustomer(customer) && !productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT)) ||
            isNLDCustomer(customer) ||
            isGBRCustomer(customer)),
        translation: 'header.navigation.menu.banking.subTitle.noticeDeposit',
      },
      {
        id: 'investment-products-DWM',
        link: LINKS.MY_INVESTMENTS.DWM_COCKPITS,
        shouldDisplayIt: hasMigratedRobo(piaProductInstances),
        translation: 'header.navigation.menu.banking.subTitle.DWM',
      },
      {
        id: 'investment-products-configurator',
        link: PENSION_PRODUCTS_LINKS.etfcCockpit,
        shouldDisplayIt: hasEtfConfiguratorProducts(pensionProductsData) && isDEUCustomer(customer),
        translation: 'header.navigation.menu.banking.subTitle.etfConfigurator',
        external: true,
      },
      {
        id: 'crypto',
        link: EXTERNAL_LINKS.DEU.CRYPTO_COCKPIT,
        shouldDisplayIt: hasCryptoPortfolio(pensionProductsData),
        translation: 'header.navigation.subMenu.title.crypto',
        external: true,
      },
      {
        id: 'find-offers',
        link: LINKS.MY_INVESTMENTS.TERM_DEPOSIT,
        shouldDisplayIt: !shouldDisplayFindOffers(
          productAccess,
          pensionProductsData,
          piaProductInstances,
        ),
        translation: 'header.navigation.menu.banking.subTitle.findOffers',
      },
      {
        id: 'pension-products',
        link: PENSION_PRODUCTS_LINKS.cockpit,
        shouldDisplayIt: hasPPProducts(pensionProductsData) && isDEUCustomer(customer),
        translation: 'header.navigation.menu.banking.subTitle.pensionProducts',
        external: true,
      },
    ],
  },
  {
    id: 'postbox',
    shouldDisplayIt: true,
    translation: 'header.navigation.menu.banking.title.postbox',
    subMenu: [
      {
        id: 'postbox-messages',
        link: EXTERNAL_LINKS.POSTBOX.HOME,
        shouldDisplayIt: true,
        translation: 'header.navigation.menu.banking.subTitle.postboxMessages',
        external: true,
      },
      {
        id: 'postbox-documents',
        link: LINKS.POSTBOX.DOCUMENTS,
        shouldDisplayIt: true,
        translation: 'header.navigation.menu.banking.subTitle.postboxDocuments',
      },
    ],
  },
  {
    id: 'administration',
    shouldDisplayIt: !customer.is_pending_activation,
    translation: 'header.navigation.menu.banking.title.administration',
    subMenu: shouldShowNewAdministrationMenu
      ? getAdministrationSubMenuItems(customer)
      : getAdministrationSubMenuItemsLegacy(customer),
  },
];

// ----------------- Main Navigation ------------------- //
export function getMainMenu(
  customer,
  pensionProductsData,
  features,
  country,
  piaProductInstances,
  shouldHideWelcomeBonus,
  shouldShowNewAdministrationMenu,
) {
  const { isLoggedIn, productAccess } = customer;

  return [
    {
      id: MY_RAISIN_MENU_ITEM_ID,
      shouldDisplayIt: true,
      subMenu: getOnlineBankingMenu(
        customer,
        productAccess,
        pensionProductsData,
        piaProductInstances,
        shouldShowNewAdministrationMenu,
      ),
      translation: 'header.navigation.menu.title.myRaisin',
    },
    {
      id: 'get-started',
      shouldDisplayIt:
        isEURCustomer(customer) &&
        !isNLDCustomer(customer) &&
        !isFRACustomer(customer) &&
        customer.is_pending_activation,
      translation: 'header.navigation.menu.title.getStarted',
      link: EXTERNAL_LINKS[country].HOW_TO_REGISTER,
      external: true,
    },
    {
      id: 'saving-accounts',
      shouldDisplayIt:
        (isEURCustomer(customer) &&
          !isESPCustomer(customer) &&
          !isNLDCustomer(customer) &&
          !isFRACustomer(customer) &&
          !isGBRCustomer(customer)) ||
        isIRLCustomer(customer),
      translation: 'header.navigation.menu.title.savingAccounts',
      subMenu: [
        {
          id: 'fixed-term-deposit',
          link: LINKS.PRODUCTS.DEFAULT,
          shouldDisplayIt:
            productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS) ||
            productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS),
          translation: 'header.navigation.subMenu.title.fixedDeposit',
        },
        // TODO: Enable it once Klarna launches Overnight offers for IE (OA-7274)
        // {
        //   id: 'overnight',
        //   link: LINKS.PRODUCTS.OVERNIGHT,
        //   shouldDisplayIt: productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS),
        //   translation: 'header.navigation.subMenu.title.overnightDeposit',
        // },
      ],
    },
    {
      id: 'offers',
      shouldDisplayIt:
        isDEUCustomer(customer) ||
        isAUTCustomer(customer) ||
        isESPCustomer(customer) ||
        isNLDCustomer(customer) ||
        isFRACustomer(customer) ||
        isGBRCustomer(customer),
      translation: 'header.navigation.menu.title.offers',
      subMenu: [
        {
          id: 'all-savings-accounts',
          link: LINKS.PRODUCTS.DEFAULT,
          shouldDisplayIt:
            isGBRCustomer(customer) &&
            (productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS) ||
              productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS) ||
              productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS) ||
              productAccess.includes(HAS_NOTICE_DEPOSIT_ACCESS)),
          translation: 'header.navigation.subMenu.title.allSavingsAccounts',
        },
        {
          id: 'fixed-term-deposit',
          link: LINKS.PRODUCTS.DEFAULT,
          shouldDisplayIt:
            productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS) ||
            productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS),
          translation: 'header.navigation.subMenu.title.fixedDeposit',
        },
        {
          id: 'overnight',
          link: LINKS.PRODUCTS.OVERNIGHT,
          shouldDisplayIt: productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS),
          translation: 'header.navigation.subMenu.title.overnightDeposit',
        },
        {
          id: 'notice-fee',
          link: LINKS.PRODUCTS.NOTICE,
          shouldDisplayIt:
            productAccess.includes(HAS_NOTICE_DEPOSIT_ACCESS) &&
            !isNLDCustomer(customer) &&
            !isESPCustomer(customer),
          translation: 'header.navigation.subMenu.title.noticeDeposit',
        },
        {
          id: 'digital-wealth-management',
          link: LINKS.MY_INVESTMENTS.DWM_LP,
          shouldDisplayIt: productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS),
          translation: 'header.navigation.menu.banking.subTitle.DWM',
        },
        {
          id: 'etf-configurator',
          link: LINKS.PRODUCTS.INVESTMENT_PRODUCTS_CONFIGURATOR,
          shouldDisplayIt: productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS),
          translation: 'header.navigation.subMenu.title.etfConfigurator',
        },
        {
          id: 'crypto',
          link: EXTERNAL_LINKS[country].CRYPTO_LANDING_PAGE,
          shouldDisplayIt:
            features.isCryptoEnabled &&
            isDEUCustomer(customer) &&
            productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS),
          translation: 'header.navigation.subMenu.title.crypto',
          external: true,
        },
        {
          id: 'etf-saving-plans',
          link: EXTERNAL_LINKS[country].ETF_SAVING_PLANS,
          shouldDisplayIt: !isLoggedIn && isDEUCustomer(customer),
          translation: 'header.navigation.subMenu.title.etfSavingPlans',
          external: true,
        },
        {
          id: 'raisin-pension',
          link: EXTERNAL_LINKS[country].RAISIN_PENSION,
          shouldDisplayIt: features.pp_fairr_lead_out,
          translation: 'header.navigation.subMenu.title.raisinPension',
          external: true,
        },
      ],
    },
    {
      id: 'bonus',
      shouldDisplayIt: shouldDisplayBonusMenu(customer, shouldHideWelcomeBonus),
      translation: 'header.navigation.menu.title.bonus',
      subMenu: [
        {
          id: 'welcome-bonus',
          shouldDisplayIt:
            isEURCustomer(customer) && customer.is_pending_activation && !shouldHideWelcomeBonus,
          translation: 'header.navigation.subMenu.title.welcomeBonus',
          link: EXTERNAL_LINKS[country].WELCOME_BONUS,
          external: true,
        },
        {
          id: 'refer-a-friend',
          shouldDisplayIt: isEURCustomer(customer),
          translation: 'header.navigation.subMenu.title.referAFriend',
          link: LINKS.REFER_A_FRIEND,
        },
      ],
    },
    {
      id: 'banks-for-business',
      shouldDisplayIt: customer.is_company_customer,
      translation: 'header.navigation.subMenu.title.banks',
      link: EXTERNAL_LINKS.DEU.BUSINESS.BANKS,
      external: true,
    },
    {
      id: 'finance',
      shouldDisplayIt:
        (isDEUCustomer(customer) || isAUTCustomer(customer)) &&
        !isDistributorCustomer(customer.distributor_id),
      translation: 'header.navigation.menu.title.finance',
      subMenu: [
        {
          id: 'investment',
          link: EXTERNAL_LINKS[country].INVESMENT,
          shouldDisplayIt: isDEUCustomer(customer) && !customer.is_company_customer,
          translation: 'header.navigation.subMenu.title.investment',
          external: true,
        },
        {
          id: 'savings',
          link: EXTERNAL_LINKS[country].SAVINGS,
          shouldDisplayIt: isDEUCustomer(customer) && !customer.is_company_customer,
          translation: 'header.navigation.subMenu.title.savings',
          external: true,
        },
        {
          id: 'pension',
          link: EXTERNAL_LINKS[country].PENSION,
          shouldDisplayIt: isDEUCustomer(customer) && !customer.is_company_customer,
          translation: 'header.navigation.subMenu.title.pension',
          external: true,
        },
        {
          id: 'magazine',
          link: EXTERNAL_LINKS[country].MAGAZINE,
          shouldDisplayIt: isDEUCustomer(customer) || isAUTCustomer(customer),
          translation: 'header.navigation.subMenu.title.magazine',
          external: true,
        },
        {
          id: 'tax-information',
          link: customer.is_company_customer
            ? EXTERNAL_LINKS.DEU.BUSINESS.TAX_INFORMATION
            : EXTERNAL_LINKS[country].TAX_INFORMATION,
          shouldDisplayIt: isDEUCustomer(customer),
          translation: 'header.navigation.subMenu.title.taxInformation',
          external: true,
        },
        {
          id: 'insurance-deposit',
          link: customer.is_company_customer
            ? EXTERNAL_LINKS.DEU.BUSINESS.INSURANCE_DEPOSIT
            : EXTERNAL_LINKS[country].INSURANCE_DEPOSIT,
          shouldDisplayIt: isDEUCustomer(customer) || isAUTCustomer(customer),
          translation: 'header.navigation.subMenu.title.insuranceDeposit',
          external: true,
        },
      ],
    },
    {
      id: 'banks',
      shouldDisplayIt: isFRACustomer(customer),
      translation: 'header.navigation.menu.title.banks',
      link: EXTERNAL_LINKS[country].BANKS,
      external: true,
    },
    {
      id: 'about-us',
      shouldDisplayIt:
        (isDEUCustomer(customer) || isAUTCustomer(customer) || isEURCustomer(customer)) &&
        !isFRACustomer(customer) &&
        !isNLDCustomer(customer) &&
        !isDistributorCustomer(customer.distributor_id),
      translation: 'header.navigation.menu.title.aboutUs',
      subMenu: ABOUT_US_SUBMENU_ITEMS(country, customer),
    },
    {
      id: 'help-and-info',
      shouldDisplayIt: isEURCustomer(customer),
      translation: 'header.navigation.menu.title.helpAndInfo',
      subMenu: [
        {
          id: 'how-to-invest',
          link: EXTERNAL_LINKS[country].HOW_TO_INVEST,
          shouldDisplayIt: isFRACustomer(customer),
          translation: 'header.navigation.subMenu.title.howToInvest',
          external: true,
        },
        {
          id: 'about-us',
          link: EXTERNAL_LINKS[country].ABOUT_US,
          shouldDisplayIt: isFRACustomer(customer),
          translation: 'header.navigation.subMenu.title.aboutUs',
          external: true,
        },
        {
          id: 'deposit-guarantee',
          link: EXTERNAL_LINKS[country].DEPOSIT_GUARANTEE,
          shouldDisplayIt: isEURCustomer(customer) && !isGBRCustomer(customer),
          translation: 'header.navigation.subMenu.title.depositGuarantee',
          external: true,
        },
        {
          id: 'saving-in-europe',
          link: EXTERNAL_LINKS[country].SAVING_IN_EUROPE,
          shouldDisplayIt: isESPCustomer(customer),
          translation: 'header.navigation.subMenu.title.savingInEurope',
          external: true,
        },
        // duplicate "faqs" section added for GBR to satisfy GBR menu items order
        {
          id: 'faqs',
          link: EXTERNAL_LINKS[country].FAQS,
          shouldDisplayIt:
            isEURCustomer(customer) &&
            !isESPCustomer(customer) &&
            !isNLDCustomer(customer) &&
            !isFRACustomer(customer) &&
            !isIRLCustomer(customer),
          translation: 'header.navigation.subMenu.title.faqs',
          external: true,
          newTab: true,
        },
        // duplicate "contact" section added for GBR to satisfy GBR menu items order
        {
          id: 'contact',
          link: EXTERNAL_LINKS[country].CONTACT,
          shouldDisplayIt:
            isEURCustomer(customer) &&
            !isESPCustomer(customer) &&
            !isNLDCustomer(customer) &&
            !isFRACustomer(customer) &&
            !isIRLCustomer(customer),
          translation: 'header.navigation.subMenu.title.contact',
          external: true,
        },
        {
          id: 'tax-information',
          link: EXTERNAL_LINKS[country].TAX_INFORMATION,
          shouldDisplayIt:
            isESPCustomer(customer) || isIRLCustomer(customer) || isNLDCustomer(customer),
          translation: 'header.navigation.subMenu.title.taxInformation',
          external: true,
        },
        {
          id: 'faqs',
          link: EXTERNAL_LINKS[country].FAQS,
          shouldDisplayIt: isESPCustomer(customer) || isIRLCustomer(customer),
          translation: 'header.navigation.subMenu.title.faqs',
          external: true,
          newTab: true,
        },
        {
          id: 'savings-guide',
          link: EXTERNAL_LINKS[country].SAVINGS_GUIDE,
          shouldDisplayIt: isFRACustomer(customer),
          translation: 'header.navigation.subMenu.title.savingsGuide',
          external: true,
        },
        {
          id: 'contact',
          link: EXTERNAL_LINKS[country].CONTACT,
          shouldDisplayIt: isESPCustomer(customer) || isIRLCustomer(customer),
          translation: 'header.navigation.subMenu.title.contact',
          external: true,
        },
        {
          id: 'press',
          link: EXTERNAL_LINKS[country].PRESS,
          shouldDisplayIt: isFRACustomer(customer) || isGBRCustomer(customer),
          translation: 'header.navigation.subMenu.title.press',
          external: true,
        },
        {
          id: 'uk-guides',
          link: EXTERNAL_LINKS.GBR.UK_GUIDES,
          shouldDisplayIt: isGBRCustomer(customer),
          translation: 'header.navigation.subMenu.title.ukGuides',
          external: true,
        },
        // duplicate tax-information section added for GBR to satisfy GBR menu items order
        {
          id: 'tax-information',
          link: EXTERNAL_LINKS[country].TAX_INFORMATION,
          shouldDisplayIt: isGBRCustomer(customer),
          translation: 'header.navigation.subMenu.title.taxInformation',
          external: true,
        },
        {
          id: 'taxes-guide',
          link: EXTERNAL_LINKS[country].TAXES_GUIDE,
          shouldDisplayIt: isIRLCustomer(customer),
          translation: 'header.navigation.subMenu.title.taxesGuide',
          external: true,
        },
        // duplicate savings-guide section added for GBR to satisfy GBR menu items order
        {
          id: 'savings-guide',
          link: EXTERNAL_LINKS[country].SAVINGS_GUIDE,
          shouldDisplayIt: isGBRCustomer(customer) || isIRLCustomer(customer),
          translation: 'header.navigation.subMenu.title.savingsGuide',
          external: true,
        },
        // duplicate investment-guide section added for GBR to satisfy GBR menu items order
        {
          id: 'investment-guide',
          link: EXTERNAL_LINKS[country].INVESTMENT_GUIDE,
          shouldDisplayIt: isGBRCustomer(customer),
          translation: 'header.navigation.subMenu.title.investmentGuide',
          external: true,
        },
        {
          id: 'banking-guide',
          link: EXTERNAL_LINKS.GBR.BANKING_GUIDE,
          shouldDisplayIt: isGBRCustomer(customer) || isIRLCustomer(customer),
          translation: 'header.navigation.subMenu.title.bankingGuides',
          external: true,
        },
        {
          id: 'personal-finance-guide',
          link: EXTERNAL_LINKS[country].PERSONAL_FINANCE_GUIDE,
          shouldDisplayIt: false && isESPCustomer(customer), // TODO remove boolean false once we have a link created for personal finance guide
          translation: 'header.navigation.subMenu.title.personalFinanceGuide',
          external: true,
        },
        {
          id: 'saving',
          link: EXTERNAL_LINKS[country].SAVING,
          shouldDisplayIt: isNLDCustomer(customer),
          translation: 'header.navigation.subMenu.title.saving',
          external: true,
        },
        {
          id: 'saving-abroad',
          link: EXTERNAL_LINKS[country].SAVING_ABROAD,
          shouldDisplayIt: isNLDCustomer(customer),
          translation: 'header.navigation.subMenu.title.savingAbroad',
          external: true,
        },
        {
          id: 'pensions-guide',
          link: EXTERNAL_LINKS.GBR.PENSIONS_GUIDE,
          shouldDisplayIt: isGBRCustomer(customer),
          translation: 'header.navigation.subMenu.title.pensionGuides',
          external: true,
        },
      ],
    },
    // duplicate about-us section added for NLD to satisfy NLD menu items order
    {
      id: 'about-us',
      shouldDisplayIt: isNLDCustomer(customer),
      translation: 'header.navigation.menu.title.aboutUs',
      subMenu: ABOUT_US_SUBMENU_ITEMS(country, customer),
    },
    {
      id: 'service-and-contact',
      shouldDisplayIt: isNLDCustomer(customer),
      translation: 'header.navigation.menu.title.serviceAndContact',
      link: EXTERNAL_LINKS[country].SERVICE_AND_CONTACT,
      external: true,
    },
  ];
}
