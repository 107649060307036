import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import constants from 'ui-lib/src/constants';
import { ajaxStatus, investmentProducts } from '../../../config';

export const initialState = {
  portfolios: [],
  order: {
    fields: [],
    amount: null,
    order_number: '',
    portfolio_id: '',
    initialAmount: investmentProducts.initialAmount,
    initialSPAmount: investmentProducts.initialSPAmount,
    form: {
      status: ajaxStatus.notPosted,
      error: null,
    },
    mTanForm: {
      status: ajaxStatus.notPosted,
      error: null,
    },
    session: {
      error: null,
      status: null,
      nonce: null,
    },
    appropriatenessTest: {
      equity_domestic_experience: false,
      fixed_income_domestic_experience: false,
      fixed_income_foreign_experience: false,
      funds_eu_experience: false,
      funds_non_eu_experience: false,
      certificates_simple_experience: false,
      forex_experience: false,
      equity_foreign_experience: false,
      certificates_advanced_experience: false,
      closed_end_funds_experience: false,
      asset_investments_experience: false,
      derivatives_experience: false,
      appropriateness_warning_accepted: false,
    },
    productExplanation: {
      information_receipt_terms_and_conditions_accepted: false,
      custody_bank_account_opening_accepted: false,
    },
    legalNotice: {
      risk_warning_accepted: false,
      custody_bank_conditions_accepted: false,
      raisin_courier_and_custody_bank_trading_accepted: false,
    },
    furtherInformation: {
      occupation: '',
      industry: '',
      annual_income: 0,
      liquid_net_worth: 0,
      tax_id_number: '',
      german_tax_residency_confirmed: false,
      servicing_bank_identification_accepted: false,
      nationalIdentifier0: '',
      nationalIdentifier1: '',
    },
    savingsPlan: {
      amount: {
        value: null,
        currency: 'EUR',
      },
      direct_debit_mandate: false,
      iban: '',
    },
    transient: {
      responses: {
        savings_plan_order: {},
      },
    },
  },
  investment: {
    allowed_actions: {
      buy_order: false,
      sell_full_order: false,
      sell_partial_order: false,
    },
    portfolio_id: '',
    composition: [],
    orders: {},
    rebalancing: {},
    fees: {},
    dividends: {},
    order_number: '',
    cash_balance: {
      currency: 'EUR',
      value: 0,
    },
    asset_balance: {
      currency: 'EUR',
      value: 0,
    },
    ip_account_value: {
      currency: 'EUR',
      value: 0,
    },
    cash_allocation_size: 0,
    asset_allocation_size: 0,
    value_snapshot: {
      ip_account_balance: {
        currency: '',
        value: 0,
      },
      inflows: {
        currency: '',
        value: 0,
      },
      outflows: {
        currency: '',
        value: 0,
      },
      taxes: {
        currency: '',
        value: 0,
      },
      invested_amount: {
        currency: '',
        value: 0,
      },
      market_return: {
        currency: '',
        value: 0,
      },
      distributions: {
        currency: '',
        value: 0,
      },
      fees: {
        currency: '',
        value: 0,
      },
      absolute_return: {
        currency: '',
        value: 0,
      },
    },
    history: {
      summary: {
        date_range: {
          from: new Date(-8640000000000000),
          to: new Date(8640000000000000),
        },
        isins: [],
        sample_rate: 'DAY',
      },
      data: [],
    },
    documents: {},
  },
  buy: {
    amount: 0,
    legalStatements: {
      servicing_bank_money_transfer_accepted: false,
      raisin_courier_and_custody_bank_trading_accepted: false,
      risk_warning_accepted: false,
      information_receipt_terms_and_conditions_accepted: false,
    },
    fields: [],
    form: {
      status: ajaxStatus.notPosted,
      error: null,
    },
    mTanForm: {
      status: ajaxStatus.notPosted,
      error: null,
    },
    session: {
      error: null,
      status: null,
      nonce: null,
    },
  },
  sell: {
    amount: 0,
    type: null,
    legalStatements: {
      'raisin_courier_and_custody_bank_trading_accepted-SELL_FULL': false,
      'raisin_courier_and_custody_bank_trading_accepted-SELL_PARTIAL': false,
    },
    fields: [],
    form: {
      status: ajaxStatus.notPosted,
      error: null,
    },
    mTanForm: {
      status: ajaxStatus.notPosted,
      error: null,
    },
    session: {
      error: null,
      status: null,
      nonce: null,
    },
  },
  savingsPlan: {
    status: {
      active: false,
      amount: { value: 0 },
      allowed_actions: [],
      order_number: '',
      portfolio_id: '',
      reference_account: {},
      next_debit_date: '',
    },
    reference_accounts: [],
    amount: '',
    min_amount: null,
    max_amount: null,
    action: null,
    legalStatements: {},
    fields: [],
    form: {
      status: ajaxStatus.notPosted,
      error: null,
    },
    mTanForm: {
      status: ajaxStatus.notPosted,
      error: null,
    },
    session: {
      error: null,
      status: null,
      nonce: null,
    },
    transient: { responses: {} },
  },
  pia: {
    productInstances: [],
  },
  pom: {
    portfolioOrders: [],
    pomProducts: {},
  },
};

export const getContent = (value) => {
  const content = [
    {
      value,
      name: 'productCard.etf',
    },
  ];

  if (value < 100) {
    content.push({
      value: 100 - value,
      name: 'productCard.bond',
      type: constants.assetTypes.bond,
    });
  }

  return content;
};

export const getPortfolio = (state, portfolioParam) => {
  const portfolio_id = portfolioParam || state.investmentProducts.investment.portfolio_id;
  const portfolio = state.investmentProducts.portfolios[portfolio_id];

  if (!portfolio) return {};

  const portfolioValue = Number(portfolio.portfolio_group_identifier);
  const content = getContent(portfolioValue);
  const risk = portfolio.risk_to_return;
  const fees = {
    service: portfolio.service_charge + portfolio.custodian_service_charge,
    thirdParty: portfolio.third_party_cost,
    serviceCharge: portfolio.service_charge,
    custodianServiceCharge: portfolio.custodian_service_charge,
  };

  return {
    ...portfolio,
    content,
    risk,
    portfolioValue,
    fees,
  };
};

export const getPortfolioForSavingsPlan = (state) => {
  // if the customer has an active savings plan, return the portfolio for which it was created
  // otherwise delegate to getPortfolio logic
  if (state.investmentProducts.savingsPlan.status.active) {
    return getPortfolio(state, state.investmentProducts.savingsPlan.status.portfolio_id);
  }

  return getPortfolio(state);
};

export const getPortfolios = (state) => {
  if (isEmpty(state.investmentProducts.portfolios)) {
    return [];
  }

  const portfolios = Object.values(state.investmentProducts.portfolios)
    .map(
      ({
        id,
        portfolio_group_identifier,
        risk_to_return,
        portfolio_group,
        family_name,
        short_name,
      }) => {
        const value = Number(portfolio_group_identifier);
        const content = getContent(value);

        return {
          id,
          value,
          content,
          name: portfolio_group_identifier,
          risk: risk_to_return,
          group: portfolio_group,
          familyName: family_name,
          shortName: short_name,
        };
      },
    )
    .sort((a, b) => a.value - b.value);

  return portfolios;
};

export const getComposition = (state) => {
  const shouldSeeCockpitSnapshot = state.ui.features.ip_view_cockpit_performance_snapshot;

  if (shouldSeeCockpitSnapshot) {
    const snapshotComposition = state.investmentProducts.investment.value_snapshot.composition;

    if (snapshotComposition && !isEmpty(snapshotComposition)) {
      return snapshotComposition;
    }
  }

  return state.investmentProducts.investment.composition;
};

export const getAccountBalance = (state) => {
  const shouldSeeCockpitSnapshot = state.ui.features.ip_view_cockpit_performance_snapshot;

  if (shouldSeeCockpitSnapshot) {
    const accountBalanceSnapshot =
      state.investmentProducts.investment.value_snapshot.ip_account_balance;

    if (accountBalanceSnapshot) {
      return accountBalanceSnapshot;
    }
  }

  return state.investmentProducts.investment.ip_account_value;
};

export const getCashBalance = (state) => {
  const shouldSeeCockpitSnapshot = state.ui.features.ip_view_cockpit_performance_snapshot;

  if (shouldSeeCockpitSnapshot) {
    const snapshotCashBalance = state.investmentProducts.investment.value_snapshot.cash_balance;

    if (snapshotCashBalance) {
      return snapshotCashBalance.value;
    }
  }
  const investmentCashBalance = state.investmentProducts.investment.cash_balance;

  return investmentCashBalance;
};

export const getAssets = (state, portfolioParam) => {
  const portfolio_id = portfolioParam || state.investmentProducts.investment.portfolio_id;
  const portfolio = state.investmentProducts.portfolios[portfolio_id];

  if (!portfolio) {
    return [];
  }

  return portfolio.composition;
};

export const getDocuments = (state, portfolioParam) => {
  const portfolio_id = portfolioParam || state.investmentProducts.investment.portfolio_id;
  const portfolio = state.investmentProducts.portfolios[portfolio_id];

  if (!portfolio) {
    return [];
  }

  return portfolio.documents;
};

export const getOrderCurrentAmount = (state) => {
  const amount = state.investmentProducts.order.amount || initialState.amount;

  if (isFinite(amount)) {
    return amount;
  }

  return 0;
};

export const getReferenceAccountFromSavingsPlan = (state) => {
  const referenceAccount = state.investmentProducts.savingsPlan.reference_accounts[0];

  return referenceAccount || {};
};
