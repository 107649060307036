import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'ui-lib/src/components/Modal';
import NationalIdentifierModal from 'components/NationalIdentifierModal';
import IPRiskWarningsModalContent from 'components/IPRiskWarningsModalContent';
import { changeUIState } from 'store/ui/actions';
import PendingUploadModalContent from '../Identification/IdentificationEur/PendingUploadModalContent';
import config from '../../config';

/**
 * Components that can be rendered outside of the page layout
 * (modals, popovers etc)
 */
export class Globals extends Component {
  static propTypes = {
    isIPRiskModalOpen: PropTypes.bool,
    isPendingUploadModalOpen: PropTypes.bool,
    isNationalIdentifierModalOpen: PropTypes.bool,
    onChangeUIState: PropTypes.func,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.onOpenRiskModal = () => props.onChangeUIState({ isIPRiskModalOpen: true });
    this.onCloseRiskModal = () => props.onChangeUIState({ isIPRiskModalOpen: false });
    this.onClosePendingUploadModal = () =>
      props.onChangeUIState({ isPendingUploadModalOpen: false });
    this.closeNationalIdentifierModal = () =>
      props.onChangeUIState({ isNationalIdentifierModalOpen: false });
    this.handleGlobalEvents = this.handleGlobalEvents.bind(this);
  }

  componentDidMount() {
    // Sometimes we have to trigger modals outside of react world
    // e.g. links on translations, this catches these cases
    this.pageLinks = Array.from(document.querySelectorAll('a'));
    this.pageLinks.forEach((el) => el.addEventListener('click', this.handleGlobalEvents));
    window.addEventListener('hashchange', this.onCloseModal);
    document.addEventListener('click', this.handleGlobalEvents);
  }

  componentWillUnmount() {
    this.pageLinks.forEach((el) => el.removeEventListener('click', this.handleGlobalEvents));
    window.removeEventListener('hashchange', this.onCloseModal);
    document.removeEventListener('click', this.handleGlobalEvents);
  }

  redirectToOffers = () => {
    this.props.history.push('/Products');
  };

  handleGlobalEvents(e) {
    const { history } = this.props;
    const hrefCurrentTarget = e.currentTarget.href;
    const hrefTarget = e.target.href;
    const href = hrefCurrentTarget || hrefTarget;

    if (href) {
      if (href.includes(config.postbox.customerServiceEmail)) {
        e.preventDefault();
        history.push(config.postbox.newMessage);
      } else if (href.includes('ip-risk-modal')) {
        e.preventDefault();
        this.onOpenRiskModal();
      }
    }
  }

  render() {
    const {
      isIPRiskModalOpen,
      isPendingUploadModalOpen,
      isNationalIdentifierModalOpen,
    } = this.props;

    return (
      <>
        {isIPRiskModalOpen ? (
          <Modal
            isOpen={isIPRiskModalOpen}
            onClose={this.onCloseRiskModal}
            ariaHideApp={false}
            id="productDescriptionWarnings"
          >
            <IPRiskWarningsModalContent />
          </Modal>
        ) : null}
        {isPendingUploadModalOpen ? (
          <Modal
            isOpen={isPendingUploadModalOpen}
            onClose={this.onClosePendingUploadModal}
            ariaHideApp={false}
            id="pendingUploadWarning"
          >
            <PendingUploadModalContent />
          </Modal>
        ) : null}
        {isNationalIdentifierModalOpen ? (
          <Modal
            isOpen={isNationalIdentifierModalOpen}
            onClose={this.closeNationalIdentifierModal}
            ariaHideApp={false}
            id="nationalIdentifier"
          >
            <NationalIdentifierModal />
          </Modal>
        ) : null}
     </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onChangeUIState: (subState) => dispatch(changeUIState(subState)),
});

const mapStateToProps = (state) => ({
  isIPRiskModalOpen: state.ui.isIPRiskModalOpen,
  isPendingUploadModalOpen: state.ui.isPendingUploadModalOpen,
  isNationalIdentifierModalOpen: state.ui.isNationalIdentifierModalOpen,
  featureFlags: state.global.featureFlags,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Globals));
