import { format, add } from 'date-fns';
import * as types from '../../../actionTypes';
import { createImprovedAsyncAction } from '../../../actionCreators';

export const CONFIGURATOR_MIGRATION_CONSENT = 'CONFIGURATOR_MIGRATION_CONSENT';
export const CRYPTO_MIGRATION_CONSENT = 'CRYPTO_MIGRATION_CONSENT';

const migrationConsentTypes = new Set([CONFIGURATOR_MIGRATION_CONSENT, CRYPTO_MIGRATION_CONSENT]);

export const postPomDraftOrder = (customerId, type) => {
  if (!migrationConsentTypes.has(type)) {
    throw new Error('Invalid migration consent type: ', type);
  }

  return createImprovedAsyncAction({
    type:
      type === CONFIGURATOR_MIGRATION_CONSENT
        ? types.POM_POST_ETFC_DRAFT_ORDER
        : types.POM_POST_CRYPTO_DRAFT_ORDER,
    path: 'pom.customerOrders',
    method: 'post',
    params: {
      customer_domain_id: customerId,
      order_type: type,
    },
    settings: {
      useJwt: true,
    },
  });
};

export const postPomValidateOrder = (customerId, type, orderDomainId) => {
  if (!migrationConsentTypes.has(type)) {
    throw new Error('Invalid migration consent type: ', type);
  }

  return createImprovedAsyncAction({
    type:
      type === CONFIGURATOR_MIGRATION_CONSENT
        ? types.POM_POST_ETFC_VERIFY_ORDER
        : types.POM_POST_CRYPTO_VERIFY_ORDER,
    path: 'pom.validateOrder',
    method: 'post',
    dynamicPath: { order_domain_id: orderDomainId },
    params: {
      customer_domain_id: customerId,
      order_domain_id: orderDomainId,
      order_type: type,
    },
    settings: {
      useJwt: true,
    },
  });
};

export const fetchPomETFCMigrationConsent = (customerId) => {
  return createImprovedAsyncAction({
    type: types.POM_FETCH_ETFC_CONSENT_ORDERS,
    path: 'pom.customerOrders',
    params: {
      customerId,
      type: CONFIGURATOR_MIGRATION_CONSENT,
      state: 'CONFIRMED',
    },
    settings: {
      useJwt: true,
    },
  });
};

export const fetchPomCryptoMigrationConsent = (customerId) => {
  return createImprovedAsyncAction({
    type: types.POM_FETCH_CRYPTO_CONSENT_ORDERS,
    path: 'pom.customerOrders',
    params: {
      customerId,
      type: CRYPTO_MIGRATION_CONSENT,
      state: 'CONFIRMED',
    },
    settings: {
      useJwt: true,
    },
  });
};

export const fetchPomCustomerOrders = (customerId, type, state) => {
  return createImprovedAsyncAction({
    type: types.POM_FETCH_CUSTOMER_ORDERS,
    path: 'pom.customerOrders',
    settings: {
      params: {
        customerId,
        type,
        state,
      },
      useJwt: true,
    },
  });
};

export const fetchPomProducts = () => {
  return createImprovedAsyncAction({
    type: types.POM_FETCH_PRODUCTS_ACCESS,
    path: 'pom.products',
    settings: {
      useJwt: true,
    },
  });
};

export const fetchPomPortfolioOrders = (productInstanceDomainId, since) => {
  const startDate = since instanceof Date ? since : add(new Date(), since);

  return createImprovedAsyncAction({
    type: types.POM_FETCH_PORTFOLIO_ORDERS,
    path: 'pom.portfolioOrders',
    settings: {
      params: {
        productInstanceDomainId,
        since: format(startDate, 'yyyy-MM-dd'),
      },
      useJwt: true,
    },
  });
};
