import * as types from '../../actionTypes';

export const changePensionProductType = (type) => ({
  type: types.CHANGE_PENSION_PRODUCT_TYPE,
  payload: type,
});

export const changePPTargetLink = (link) => ({
  type: types.CHANGE_PP_TARGET_LINK,
  payload: link,
});
