import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import ErrorBoundary from 'components/ErrorBoundary';
import Footer from 'components/Footer';
import {
  fetchPensionProductsDashboardData,
  fetchPensionProductsDashboardDataMigrated,
} from 'store/dashboard/actions';
import { fetchFeatureFlags } from 'store/global/actions';
import { hasCookieForMobile, isDEUCustomer } from 'utils/CustomerUtils';
import { getPortfolio } from 'store/investmentProducts/selectors';
import Header from 'components/Header';
import RoboAwardHeader from './Headers/RoboAwardHeader';
import styles from './styles.scss';

export class Layout extends Component {
  static propTypes = {
    children: PropTypes.node,
    fetchPensionProductsDashboardData: PropTypes.func.isRequired,
    fetchPensionProductsDashboardDataMigrated: PropTypes.func.isRequired,
    customer: PropTypes.shape({
      is_pending_activation: PropTypes.bool,
      region: PropTypes.string.isRequired,
      productAccess: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      isLoggedIn: PropTypes.bool,
      bac_number: PropTypes.string,
    }),
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    hideMenu: PropTypes.bool,
    isIPRoute: PropTypes.bool,
    bacNumber: PropTypes.string.isRequired,
    portfolio: PropTypes.shape({
      portfolio_provider: PropTypes.string,
    }).isRequired,
    featureFlags: PropTypes.object.isRequired,
    isFetchingFeatureFlags: PropTypes.bool,
  };

  static defaultProps = {
    customer: {
      is_pending_activation: false,
      region: 'DEU',
      productAccess: [],
      isLoggedIn: false,
    },
    showHeader: true,
    showFooter: true,
    hideMenu: false,
  };

  state = {
    isDataFetched: false,
  };

  componentDidMount() {
    if (this.props.customer.bac_number) {
      // refresh feature flags once bac_number is available
      this.props.fetchFeatureFlags();
    }
  }

  componentDidUpdate() {
    const {
      isFetchingFeatureFlags,
      customer,
      fetchPensionProductsDashboardDataMigrated,
    } = this.props;

    const { isDataFetched } = this.state;

    if (isDEUCustomer(customer) && !isFetchingFeatureFlags && !isDataFetched) {
      fetchPensionProductsDashboardDataMigrated();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isDataFetched: true });
    }
  }

  render() {
    const {
      bacNumber,
      customer: { isLoggedIn, isFetchingProductAccess },
      hideMenu,
      portfolio,
      showFooter,
      showHeader,
      isFetchingFeatureFlags,
      children,
    } = this.props;

    if (isLoggedIn && (isFetchingFeatureFlags || isFetchingProductAccess)) {
      return <LoadingPlaceholder height={300} width="100%" />;
    }

    return (
      <div className={styles.contentWrapper}>
        {hideMenu || hasCookieForMobile() ? null : (
          <ErrorBoundary>
            <Header />
          </ErrorBoundary>
        )}
        {showHeader && !hasCookieForMobile() ? (
          <RoboAwardHeader
            bacNumber={bacNumber}
            portfolio={portfolio}
            eventEndDate={new Date('2020-10-23T23:00:00.000')}
          />
        ) : null}
        <section className={styles.content}>
          <div className="container">
            <div className="row">
              <div className="col-xs-12">{children}</div>
            </div>
          </div>
        </section>
        {showFooter && !hasCookieForMobile() && (
          <ErrorBoundary>
            <Footer />
          </ErrorBoundary>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bacNumber: state.customer.bac_number,
  customer: state.customer,
  portfolio: getPortfolio(state),
  isFetchingFeatureFlags: state.global.isFetchingFeatureFlags,
  featureFlags: state.global.featureFlags,
});

const mapDispatchToProps = {
  fetchPensionProductsDashboardData,
  fetchPensionProductsDashboardDataMigrated,
  fetchFeatureFlags,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
