import { initialState } from '../selectors';
import investmentReducer from './investment';
import portfoliosReducer from './portfolios';
import * as types from '../../actionTypes';
import savingsPlanReducer from './savingsPlan';
import piaReducer from './pia';
import pomReducer from './pom';

export default (state = initialState, action) => {
  const { type } = action;

  switch (true) {
    case type.startsWith('PIA_'):
      return {
        ...state,
        pia: piaReducer(state.pia, action),
      };
    case type.startsWith('POM_'):
      return {
        ...state,
        pom: pomReducer(state.pom, action),
      };
    case type.startsWith(types.FETCH_PORTFOLIO) || type === types.RESET_PORTFOLIOS:
      return {
        ...state,
        portfolios: portfoliosReducer(state.portfolios, action),
      };
    case type.includes('_SAVINGS_PLAN_'):
      return {
        ...state,
        savingsPlan: savingsPlanReducer(state.savingsPlan, action),
      };
    case type.includes('INVESTMENT') || type.includes('_TIMELINE_'):
      return {
        ...state,
        investment: investmentReducer(state.investment, action),
      };
    default:
      return state;
  }
};
