import { states } from 'utils/CustomerUtils';

import { getUrl } from '../utils';

export const HAS_FIXED_DEPOSIT_ACCESS = 'HAS_FIXED_DEPOSIT_ACCESS';
export const HAS_FIXED_DEPOSIT_ACCOUNT = 'HAS_FIXED_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCESS = 'HAS_FLEX_DEPOSIT_ACCESS';
export const HAS_FLEX_DEPOSIT_ACCOUNT = 'HAS_FLEX_DEPOSIT_ACCOUNT';
export const HAS_INVESTMENT_PRODUCT_ACCESS = 'HAS_INVESTMENT_PRODUCT_ACCESS';
export const HAS_INVESTMENT_PRODUCT_ACCOUNT = 'HAS_INVESTMENT_PRODUCT_ACCOUNT';
export const HAS_NOTICE_DEPOSIT_ACCESS = 'HAS_NOTICE_DEPOSIT_ACCESS';
export const HAS_NOTICE_DEPOSIT_ACCOUNT = 'HAS_NOTICE_DEPOSIT_ACCOUNT';
export const HAS_OVERNIGHT_DEPOSIT_ACCESS = 'HAS_OVERNIGHT_DEPOSIT_ACCESS';
export const HAS_OVERNIGHT_DEPOSIT_ACCOUNT = 'HAS_OVERNIGHT_DEPOSIT_ACCOUNT';

export const getEsMenus = ({
  customer,
  hasB2cDeprecateObsFeatureFlag,
}) => {
  const { productAccess, state } = customer;
  const isPendingActivation = state === states.ACTIVATION_PENDING;

  const myInvestments = {
    id: 'myInvestments',
    title: `Mi ahorro`,
    href: '',
    subMenus: {
      layout: 'even',
      top: [
        {
          id: 'dashboard-myInvestments',
          type: 'basic',
          title: 'Resumen',
          href: getUrl(`/#/dashboard`),
          icon: 'overviewIcon',
          iconColor: '#0E6CC5',
        },
      ],
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT)) && {
          id: 'fixed-deposit',
          type: 'basic',
          title: 'Mis depósitos',
          href: getUrl(`/#/MyInvestments/TermDeposit`),
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) && {
          id: 'overnight-deposit',
          type: 'basic',
          title: 'Mis cuentas de ahorro',
          href: getUrl(`/#/MyInvestments/Overnight`),
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
      ],
    },
  };

  const offers = {
    id: 'offers',
    title: `Productos`,
    href: '',
    subMenus: {
      layout: 'even',
      main: [
        (productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS) ||
          productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS) ||
          hasB2cDeprecateObsFeatureFlag) && {
          id: 'fixedDeposit-offers',
          type: 'advanced',
          title: 'Depósitos',
          href: '/products/',
          description: 'Inversiones a plazo con tipo de interés fijo',
          icon: 'fixedDepositIcon',
          iconColor: '#61AFD2',
        },
        (productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS) || hasB2cDeprecateObsFeatureFlag) && {
          id: 'overnightDeposit-offers',
          type: 'advanced',
          title: 'Cuentas de ahorro',
          href: '/products/overnight/',
          description: 'Una inversión flexible con tipo de interés variable',
          icon: 'overnightMoneyIcon',
          iconColor: '#9CD3E4',
        },
      ],
    },
  };

  const information = {
    id: 'information',
    title: 'Información',
    href: '',
    subMenus: {
      main: [
        {
          id: 'e78780a1-cc15-45d9-95ca-53c52e905cc5',
          type: 'advanced',
          title: 'Garantía de depósitos',
          href: '/fondo-garantia-depositos/',
          description: 'Todo acerca del sistema de garantía de depósitos',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt0adba2eaeb81e4b1/Garantía_de_depósitos.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '2a134210-7e96-4614-b940-318c010b7a0a',
          type: 'advanced',
          title: 'Tributación',
          href: '/impuestos/',
          description: 'Cómo tributan los productos que contratas a través de Raisin',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt0b0deb0f7f6c3701/Tributación.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: 'd63e6408-738b-4d6f-927a-bc2274804d69',
          type: 'advanced',
          title: 'Bancos',
          href: '/bancos/',
          description: 'Conoce nuestros bancos colaboradores',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt017f3eb0e4c93396/Bancos.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '5d4de868-91cd-42cc-9358-08173aa5986f',
          type: 'advanced',
          title: 'Guía del ahorro',
          href: '/ahorro/',
          description: 'Descubre las claves para un mejor ahorro',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/bltcff737ce31d3d64f/Guía_del_ahorro.svg',
          iconColor: '#0E6CC5',
        },
        {
          id: '5d4de868-91cd-42cc-9358-08173aa5986f',
          type: 'advanced',
          title: 'Guía de la inversión',
          href: '/inversion/',
          description: 'Nuestra guía definitiva para invertir dinero',
          icon: 'https://eu-images.contentstack.com/v3/assets/blt4a5ee0113ab335fb/blt7c20b2360d6a5bdf/Guía_de_la_inversión.svg',
          iconColor: '#0E6CC5',
        },
      ],
    },
  };

  const help = {
    id: '2e11a01f-4216-4fea-b05f-2fd46cf3a8b4',
    title: 'Ayuda',
    href: '',
    subMenus: {
      main: [
        isPendingActivation && {
          id: 'a9cbf2ad-81bc-4f83-8196-b67b414f9b0f',
          type: 'text',
          title: 'Cómo abrir tu Cuenta Raisin',
          href: '/como-registrarte-en-raisin/',
        },
        {
          id: '9a91930e-db2a-45be-8eab-30a081e40e03',
          type: 'text',
          title: 'Preguntas frecuentes',
          href: 'https://ayuda.raisin.es/hc/es/',
        },
        {
          id: 'c3f65470-a71c-4079-adae-a644c03c00a2',
          type: 'text',
          title: 'Atención al cliente',
          href: '/contacto/',
        },
      ],
    },
  };

  const navItems = [myInvestments, offers, information];

  return [...navItems, help];
};
