import { handle } from 'redux-pack';
import * as types from '../../../actionTypes';
import { fetchSavingsPlanData } from './alterations';

export default (state, action) => {
  // NOSONAR
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_SAVINGS_PLAN_DATA:
      return handle(state, action, {
        success: (prevState) => fetchSavingsPlanData(prevState, payload),
      });
    default:
      return state;
  }
};
