// NOSONAR
import Cookies from 'js-cookie';
import store from 'store2';

import distributors from 'utils/Distributors';
import DocumentState from 'utils/DocumentState';
import { getLocaleFromLocalStorage, LOCALE_STORAGE_KEY } from 'utils/Locale';
import isEmpty from 'lodash/isEmpty';

export const AUTH_TOKEN_KEY = 'auth_token';
export const AUTH_TIME_DIFF_KEY = 'auth_time_diff';

export const CUSTOMER_IDENTITY_PROVIDERS = {
  IDNOW: 'idnow',
};

export const PERSON_ROLE = {
  ACCOUNT_HOLDER: 'ACCOUNT_HOLDER',
  LEGAL_REPRESENTATIVE: 'LEGAL_REPRESENTATIVE',
};

export const KYC_STATUS = {
  PENDING_EXTERNAL_VERIFICATION: 'PENDING_EXTERNAL_VERIFICATION',
  PENDING_ADDITIONAL_DOCUMENTATION: 'PENDING_ADDITIONAL_DOCUMENTATION',
  PENDING_INTERNAL_VERIFICATION: 'PENDING_INTERNAL_VERIFICATION',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
};

export const KYC_EXTERNAL_VERIFICATION_STATUS = {
  NONE: 'NONE',
  INITIATED: 'INITIATED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const CUSTOMER_ACCOUNT_STATUS = {
  CREATED: 'CREATED',
  REGISTERED: 'REGISTERED',
  IDENTIFIED: 'IDENTIFIED',
  PENDING_SERVICING_BANK_ACCEPTANCE: 'PENDING_SERVICING_BANK_ACCEPTANCE',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  DISABLED: 'DISABLED',
  CLOSED: 'CLOSED',
  DELETED: 'DELETED',
  TERMINATION_REQUESTED: 'TERMINATION_REQUESTED',
  TERMINATION_INITIATED: 'TERMINATION_INITIATED',
  TERMINATED: 'TERMINATED',
};

const regions = {
  DEU: 'DEU',
  GBR: 'GBR',
};

export const countries = {
  AUT: 'AUT',
  DEU: 'DEU',
  ESP: 'ESP',
  FRA: 'FRA',
  GBR: 'GBR',
  IRL: 'IRL',
  NLD: 'NLD',
  POL: 'POL',
  FIN: 'FIN',
};

const countryByLocale = {
  de: 'DEU',
  es: 'ESP',
  nl: 'NLD',
  fr: 'FRA',
  'en-IE': 'IRL',
  'en-GB': 'GBR',
  'en-US': 'EUR',
  'de-AT': 'AUT',
  'pl-PL': 'POL',
  'fi-FI': 'FIN',
};

export const localeByCountry = {
  AUT: 'de-AT',
  DEU: 'de',
  ESP: 'es',
  EUR: 'en-US',
  FRA: 'fr',
  GBR: 'en-GB',
  IRL: 'en-IE',
  NLD: 'nl',
  POL: 'pl-PL',
  FIN: 'fi-FI',
};

const PHONE_NUMBERS = {
  de: {
    business: '030 770 191 292',
    retail: '030 21 784 002',
  },
  'de-AT': {
    business: '0720 20 50 47',
    retail: '0720 20 50 47',
  },
  'en-US': {
    business: '+49 30 770 191 295',
    retail: '+49 30 770 191 291',
  },
  'en-IE': {
    business: '+353 1 68 62 65 1',
    retail: '+353 1 68 62 65 1',
  },
  'en-GB': {
    business: '0161 601 0000',
    retail: '0161 601 0000',
  },
  fr: {
    business: '01 85 65 36 94',
    retail: '01 85 65 36 94',
  },
  es: {
    business: '+3491 769 37 80',
    retail: '+3491 769 37 80',
  },
  nl: {
    business: '020 715 9296',
    retail: '020 715 9296',
  },
};

const EMAILS = {
  de: {
    business: 'geschaeftskunden@weltsparen.de',
    retail: 'kundenservice@weltsparen.de',
  },
  'de-AT': {
    business: 'kundenservice@weltsparen.at',
    retail: 'kundenservice@weltsparen.at',
  },
  'en-US': {
    business: 'service@raisin.com',
    retail: 'service@raisin.com',
  },
  'en-IE': {
    business: 'service@raisin.ie',
    retail: 'service@raisin.ie',
  },
  'en-GB': {
    business: 'service@raisin.co.uk',
    retail: 'service@raisin.co.uk',
  },
  fr: {
    business: 'service@raisin.fr',
    retail: 'service@raisin.fr',
  },
  es: {
    business: 'servicio@raisin.es',
    retail: 'servicio@raisin.es',
  },
  nl: {
    business: 'klantenservice@raisin.nl',
    retail: 'klantenservice@raisin.nl',
  },
};

export const dbsDepositTypes = {
  OVERNIGHT: 'OVERNIGHT',
  TERM: 'TERM',
  NOTICE: 'NOTICE',
};

export const dbsDepositStates = {
  OPENING: 'OPENING',
  OPENED: 'OPENED',
  TERMINATING: 'TERMINATING',
  TERMINATED: 'TERMINATED',
  RENEWING: 'RENEWING',
  RENEWED: 'RENEWED',
  MATURING: 'MATURING',
  MATURED: 'MATURED',
  ABORTED: 'ABORTED',
};

export const shouldUseDasRate = (state, type) => {
  if (type === dbsDepositTypes.OVERNIGHT) {
    return false;
  }

  if (type === dbsDepositTypes.TERM) {
    switch (state) {
      case dbsDepositStates.OPENED:
      case dbsDepositStates.TERMINATING:
      case dbsDepositStates.TERMINATED:
      case dbsDepositStates.MATURED:
      case dbsDepositStates.RENEWED:
      case dbsDepositStates.RENEWING:
      case dbsDepositStates.MATURING:
        return true;
      default:
        return false;
    }
  }

  return false;
};

const getDomain = () => {
  const domainParts = window.location.host.split('.');

  domainParts.shift();

  /**
   * Set the 'domain' attribute of the cookie to e.g. .weltsparen.de
   * so it is also accessible on Public Website
   *
   * or
   *
   * set it to whatever the domain is, if on localhost
   */
  return domainParts.length <= 1 ? '' : `.${domainParts.join('.')}`;
};

export const getCustomerContacts = (locale, isCompanyCustomer) => {
  const phone = PHONE_NUMBERS[locale] || {};
  const email = EMAILS[locale] || {};

  return {
    phone: phone[isCompanyCustomer ? 'business' : 'retail'],
    email: email[isCompanyCustomer ? 'business' : 'retail'],
  };
};

export const cookieForSessionName = 'obs-session';
export const cookieForMobileName = 'mobile';
export const jwtForMobilePropertyName = 'mobile-jwt';
export const authToken = 'auth_token';

export const decodeJwt = (jwt) => {
  try {
    const base64Url = jwt.split('.')[1];
    const modifiedBase64Url = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const uriValue = window.atob(modifiedBase64Url);

    const payload = uriValue
      .split('')
      .map((character) => {
        const charCodeHex = character.charCodeAt(0).toString(16);
        const paddedCharCodeHex = `00${charCodeHex}`.slice(-2);

        return `%${paddedCharCodeHex}`;
      })
      .join('');

    return JSON.parse(decodeURIComponent(payload));
  } catch (_err) {
    /* eslint-disable no-console */
    console.error('failed to decode jwt');
    throw _err;
  }
};

export const getBACNumberFromJWT = () => {
  try {
    const auth = JSON.parse(localStorage.getItem(authToken));
    const token = auth?.access_token;

    if (!token) {
      localStorage.removeItem(authToken);
      Cookies.set(cookieForSessionName, '', { expires: -1, domain: getDomain(), path: '/' });

      return null;
    }
    const { bac_number: bacNumber } = decodeJwt(token);

    return Array.isArray(bacNumber) ? bacNumber[0] : bacNumber;
  } catch (error) {
    return null;
  }
};

export const hasBACNumberFromJWT = () => {
  return !!getBACNumberFromJWT();
};

export const isAccessTokenExpired = () => {
  try {
    const auth = store(AUTH_TOKEN_KEY);
    const timeDelta = Number(store(AUTH_TIME_DIFF_KEY));
    const token = auth?.access_token;

    if (!token) {
      return true;
    }

    const { exp } = decodeJwt(token);
    const expiryEpoch = (exp + timeDelta) * 1000;

    return Number.isNaN(expiryEpoch) || Date.now() > expiryEpoch;
  } catch (error) {
    return true;
  }
};

export const createCookieForSession = () => {
  const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);

  Cookies.set(cookieForSessionName, 'true', {
    expires: inFifteenMinutes,
    domain: getDomain(),
    path: '/',
  });
};

export const refreshCookieForSession = () => createCookieForSession();

export const hasCookieForSession = () => {
  const cookie = Cookies.get(cookieForSessionName);

  return !!cookie && cookie === 'true';
};

export const hasAutoLogoutTimestamp = () =>
  !!localStorage.getItem('lastSyncedAutoLogoutTimestampRefresh');

export const removeAutoLogoutTimestamp = () => {
  localStorage.removeItem('lastSyncedAutoLogoutTimestampRefresh');
};

export const setAutoLogoutTimestamp = () => {
  localStorage.setItem('lastSyncedAutoLogoutTimestampRefresh', Date.now());
};

export const hasValidAuthToken = () => {
  return (
    localStorage.hasOwnProperty('auth_token') &&
    JSON.parse(localStorage.getItem('auth_token')).access_token.length > 0
  );
};

export const hasCookieForMobile = () => {
  const cookie = Cookies.get(cookieForMobileName);

  return !!cookie && cookie === 'true';
};

export const isLoggedIn = () => !isAccessTokenExpired();

export const getJwtForMobile = () => localStorage.getItem(jwtForMobilePropertyName);

export const deleteCookieForSession = () =>
  Cookies.set(cookieForSessionName, '', { expires: -1, domain: getDomain(), path: '/' });

export const authenticationMethods = {
  M_TAN: 'M_TAN',
  MASTER_PIN: 'MASTER_PIN',
};

export const deleteAuthToken = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(AUTH_TIME_DIFF_KEY);
};

export const isDEUCustomer = ({ default_address }) =>
  default_address && default_address.country !== '' && default_address.country === countries.DEU;

export const isAUTCustomer = ({ default_address }) =>
  default_address && default_address.country !== '' && default_address.country === countries.AUT;

// legacy method to check if a customer is from anywhere BUT Germany and Austria
export const isEURCustomer = (args) => !isDEUCustomer(args) && !isAUTCustomer(args);

export const isNLDCustomer = ({ default_address }) => {
  return (
    default_address && default_address.country !== '' && default_address.country === countries.NLD
  );
};

export const isIRLCustomer = ({ default_address }) => {
  return (
    default_address && default_address.country !== '' && default_address.country === countries.IRL
  );
};

export const isESPCustomer = ({ default_address }) => {
  return (
    default_address && default_address.country !== '' && default_address.country === countries.ESP
  );
};

export const isFRACustomer = ({ default_address }) => {
  return (
    default_address && default_address.country !== '' && default_address.country === countries.FRA
  );
};

export const isGBRCustomer = ({ default_address }) => {
  return (
    default_address && default_address.country !== '' && default_address.country === countries.GBR
  );
};

export const isPOLCustomer = ({ default_address }) =>
  default_address?.country === countries.POL &&
  localStorage.getItem(LOCALE_STORAGE_KEY).toLowerCase() === 'pl-pl';

export const isFINCustomer = ({ default_address }) => {
  return (
    default_address && default_address.country !== '' && default_address.country === countries.FIN
  );
};

export const isCOMCustomer = ({ default_address }) => {
  return (
    !isGBRCustomer({ default_address }) &&
    !isFRACustomer({ default_address }) &&
    !isESPCustomer({ default_address }) &&
    !isIRLCustomer({ default_address }) &&
    !isNLDCustomer({ default_address }) &&
    !isDEUCustomer({ default_address }) &&
    !isAUTCustomer({ default_address }) &&
    !isPOLCustomer({ default_address }) &&
    !isFINCustomer({ default_address })
  );
};

export const isDEURegion = ({ region }) => region === regions.DEU;

export const isGBRRegion = ({ region }) => region === regions.GBR;

export const isSwedenBank = (branchCountry) =>
  !!branchCountry && branchCountry?.id !== '' && branchCountry?.id?.toUpperCase() === 'SWE';

export const isNorwayBank = (branchCountry) =>
  !!branchCountry && branchCountry?.id !== '' && branchCountry?.id?.toUpperCase() === 'NOR';

export const shouldHideCurrencyInDGS = (branchCountry) => {
  return isSwedenBank(branchCountry) || isNorwayBank(branchCountry);
};

export const isCompanyCustomer = ({ is_company_customer }) => is_company_customer;

export const isMtanAuth = ({ signature_method }) =>
  signature_method === authenticationMethods.M_TAN;

export const isMasterPINAuth = ({ signature_method }) =>
  signature_method === authenticationMethods.MASTER_PIN;

export const isFromCobaShort = (distributor) => distributor === distributors.COBASHORT;

export const isFromCobaFull = (distributor) => distributor === distributors.COBAFULL;

export const isFromCoba = (distributor) =>
  isFromCobaShort(distributor) || isFromCobaFull(distributor);

export const isDistributorCustomer = (distributorId) =>
  distributorId !== distributors.RAISIN &&
  distributorId !== distributors.RAISIN_BUSINESS &&
  distributorId !== distributors.PAYPAL &&
  distributorId !== '';

export const isMissingIdentificationDocument = ({ identification }) => {
  if (identification) {
    return identification.state === DocumentState.MISSING;
  }

  return false;
};

export const getCountryByLocalStorageLocale = () => {
  const locale = getLocaleFromLocalStorage();

  return countryByLocale[locale] || 'DEU';
};
export const states = {
  PASSWORD_PENDING: 'PASSWORD_PENDING',
  ACTIVATION_PENDING: 'ACTIVATION_PENDING',
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  DELETED: 'DELETED',
};

export const validityStates = {
  VALID: 'VALID',
  REVALIDATE: 'REVALIDATE',
  SUBMITTED: 'SUBMITTED',
  EXPIRED: 'EXPIRED',
};

export const CustomerStatus = {
  Registered: 'REGISTERED',
  Identified: 'IDENTIFIED',
  PendingAcceptance: 'PENDING_SERVICING_BANK_ACCEPTANCE',
  Active: 'ACTIVE',
  Rejected: 'REJECTED',
  DeletionInitiated: 'DELETION_INITIATED',
  Deleted: 'DELETED',
  TerminationRequested: 'TERMINATION_REQUESTED',
  TerminationInitiated: 'TERMINATION_INITIATED',
  Terminated: 'TERMINATED',
};

/**
 * check to see if the CAS customer is pending for activation or not
 *
 * @param {*} customerStatus (coming from CAS)
 * @returns {*}
 */
export const isCustomerPendingActivation = (customerStatus) =>
  [CustomerStatus.Registered, CustomerStatus.Identified, CustomerStatus.PendingAcceptance].includes(
    customerStatus,
  );

/**
 * Get account holder name from CAS customer
 *
 * @param {*} casCustomer
 * @returns {string}
 */
export const getAccountHolderName = (casCustomer) => {
  const { firstName = '', lastName = '' } =
    // eslint-disable-next-line no-underscore-dangle
    casCustomer?._embedded?.persons?.[0]?.personalDetails || {};

  return `${firstName} ${lastName}`;
};

/*
RestrictedCustomer means explicitly targeting "EUR" customers,
by removing other countries customers.
*/

export const isRestrictedCustomer = (customer) =>
  !isGBRCustomer(customer) &&
  !isDEUCustomer(customer) &&
  !isAUTCustomer(customer) &&
  !isIRLCustomer(customer);

const DBS_PRODUCT_TYPE_MAP = {
  TERM: 'FIXED_DEPOSIT',
  OVERNIGHT: 'OVERNIGHT_MONEY',
  NOTICE: 'NOTICE_MONEY',
};

const dbsTypeToObsType = {
  FIXED_DEPOSIT: 'term_deposits',
  OVERNIGHT_MONEY: 'overnight_deposits',
  NOTICE_MONEY: 'notice_deposits',
};

const getDepositTypeAndStatus = ({ offer_type, status }) => {
  const dbsStatusToObsStatus = {
    ORDERED: 'pending_deposits',
    OPA: 'pending_deposits',
    PRA: 'pending_deposits',
    ACR: 'pending_deposits',
    PAIN: 'pending_deposits',
    PYI: 'pending_deposits',
    PROLONGATION_ORDERED: 'pending_deposits',
    OPC: 'current_deposits',
    OPC_RECEIVED: 'current_deposits',
    PRC: 'current_deposits',
    PRC_RECEIVED: 'current_deposits',
    TRA: 'current_deposits',
    TRR: 'current_deposits',
    IN_PROLONGATION: 'current_deposits',
    MAT: 'current_deposits',
    TRA_ORDERED: 'current_deposits',
    PROLONGED: 'historical_deposits',
    PRR: 'historical_deposits',
    PYO: 'historical_deposits',
    TRC: 'historical_deposits',
    OPR: 'historical_deposits',
    CAN: 'historical_deposits',
    AUTO_REVOKED: 'REVOKED', // not displayed
    REVOKED: 'REVOKED', // not displayed
  };

  return {
    depositType: dbsTypeToObsType[offer_type],
    depositStatus: dbsStatusToObsStatus[String(status).toUpperCase()],
  };
};

const addDepositsAmounts = (deposits) => {
  return deposits.reduce(
    (acc, deposit) => ({
      ...acc,
      [deposit?.currency]:
        parseFloat(acc[deposit?.currency] || 0) + parseFloat(deposit?.balance || 0),
    }),
    {},
  );
};

const removeDuplicateDeposits = (deposits) => [
  ...new Map(deposits.map((m) => [m.deposit_number, m])).values(),
];

export const getInterestPayoutScheme = (annuityConditions) => {
  if (annuityConditions?.annuityConditions_payout) {
    const unit = annuityConditions?.frequency?.unit;
    const period = annuityConditions?.frequency?.period;

    if (unit === 'years' && period === 1) {
      return 'ANNUAL';
    }
    if (unit === 'months' && period === 3) {
      return 'QUARTERLY';
    }
    if (unit === 'months' && period === 1) {
      return 'MONTHLY';
    }
  }

  return 'MATURITY';
};

/**
 * Returns which interest rate is to be used for a given Deposit.
 *
 * @param {*} interestRateEffective Effective Interest Rate
 * @param {*} interestRateNominal Nominal Interest Rate
 * @returns Interest Rate to be displayed
 */
export const getProductInterestRateByLocale = (interestRateEffective, interestRateNominal) => {
  switch (getLocaleFromLocalStorage()) {
    case 'fr':
    case 'es':
    case 'en-GB':
    case 'en-IE':
      return interestRateEffective;
    case 'de-AT':
    case 'de':
    case 'nl':
    case 'en-US':
    default:
      return interestRateNominal;
  }
};

const getProductOffer = (product, available_actions) => {
  const { conditions } = product;
  const interestRates = product.interest_rates;
  const upcomingRates = interestRates?.upcoming_rates;

  const preContractualDocuments = product.pre_contractual_documents;

  const depositTakingBank = product.deposit_taking_bank;
  const statutoryDepositGuaranteeScheme = depositTakingBank?.statutory_deposit_guarantee_scheme;
  const productDescriptionDocument = preContractualDocuments?.find(
    (document) => document.document_type === 'PRODUCT_INFORMATION_SHEET',
  );

  return {
    id: product.id,
    bank_id: depositTakingBank?.id,
    offer_type: DBS_PRODUCT_TYPE_MAP[product.product_type],
    currency_code: product.currency,
    interest_payout_scheme: getInterestPayoutScheme(conditions?.annuity),
    interest_calculation_method: 'DAILY_ACCRUAL', // Daily applicable for all managed deposits on BIPS. Assumed deprecation of OBS-FE before we BIPsify direct deposits.
    currency_long: product.currency, // No long currency returned from the API now
    term_months_count: product?.term_normalized?.months,
    deposit_insurance_description: statutoryDepositGuaranteeScheme?.description,
    deposit_insurance_name: statutoryDepositGuaranteeScheme?.name || '',
    statutory_deposit_guarantee: statutoryDepositGuaranteeScheme,
    minimum_investment_amount: Number(conditions?.minimum_balance || 0),
    maximum_investment_amount: Number(conditions?.maximum_balance || 0),
    notice_account: product.product_type === 'NOTICE',
    premature_termination: conditions?.termination?.contractual_termination_allowed || false,
    pay_in_denied: !available_actions?.top_up_allowed,
    withdrawal_denied: !available_actions?.withdrawal_allowed,
    overnight_revenue_tab_active: product.product_type === 'OVERNIGHT',
    product_offer_rates: {
      is_rate_changing: product.product_type !== 'TERM' && !!upcomingRates?.length,
      rate_change_date_time: !!upcomingRates?.length && upcomingRates[0]?.effective_date,
      nominal_interest_rate: parseFloat(interestRates.interest_rate_nominal) || 0,
      effective_interest_rate: parseFloat(interestRates.interest_rate_effective) || 0,
      rate_change_value:
        parseFloat(
          getProductInterestRateByLocale(
            upcomingRates?.[0]?.interest_rate_effective,
            upcomingRates?.[0]?.interest_rate_nominal,
          ),
        ) || 0,
    },

    ...(productDescriptionDocument
      ? {
          product_description_document: {
            id: productDescriptionDocument.id,
            display_text: productDescriptionDocument.display_name,
            document_url: productDescriptionDocument.url,
            creation_date_time: productDescriptionDocument.created_at,
            modification_date_time: productDescriptionDocument.updated_at,
            mime_type: 'application/pdf',
          },
        }
      : {}),
    // Not rendered in the UI
    description_document_url: {
      id: 'logo_url',
      type: 'ATTACHMENT',
      url_path: 'url_path',
    },
    // Not rendered in the UI
    legal_statement_2_document: {
      id: 'logo_url',
      type: 'ATTACHMENT',
      url_path: 'url_path',
    },
    documents: preContractualDocuments?.map((document) => ({
      id: document.id,
      display_text: document.display_name,
      document_url: document.url,
      creation_date_time: null,
      modification_date_time: null,
      mime_type: null,
    })),
  };
};

const getParameters = (item) => {
  const { deposit, product } = item;
  const interestRates = product?.interest_rates;
  const upcomingRates = interestRates?.upcoming_rates;

  const { renewal } = deposit;
  const availableActions = item.available_actions;
  const requiredCustomerJobs = item.customer_jobs;

  return {
    auto_prolongation_active: renewal?.renewal_product_id === product?.id,
    calculated_end_date: deposit.maturity_date || '',
    has_successor: !!renewal?.successor_deposit_id,
    missing_funds: requiredCustomerJobs?.fund_deposit_required || false,
    ordered_prolongation_start_date: deposit.maturity_date || '',
    overnight_termination_available: availableActions?.contractual_termination_allowed || false,
    qualified_for_early_termination: false,
    premature_termination_label: product?.conditions?.termination?.contractual_termination_allowed
      ? 'true'
      : 'false',
    qualified_for_prolongation: availableActions?.renewal_changes_allowed || false,
    qualified_for_request: false, // Not rendered in the UI.
    qualified_for_termination: availableActions?.statutory_termination_allowed || false,
    rate_change_active: !!upcomingRates?.length,
    state_sensitive_balance: 0, // Gets updated later from DAS balance.amount.denomination. Before this value was returned for term deposit from OBS
  };
};

const getRequiredCustomerJobs = (requiredCustomerJobs) => {
  return {
    is_opa_document_missing: requiredCustomerJobs?.opening_application_form_required || false,
    is_idd_document_missing: requiredCustomerJobs?.kyc_required || false,
    is_pra_document_missing: requiredCustomerJobs?.renewal_application_form_required || false,
    is_tra_document_missing: requiredCustomerJobs?.termination_application_form_required || false,
  };
};

const getTerminataionDate = (terminationDate, maturityDate) => terminationDate || maturityDate;
const getPayoutDate = (depositState, terminationDate, maturityDate) =>
  depositState === dbsDepositStates.TERMINATED ? terminationDate : maturityDate;

export function getResizedImageUrl(resizableUrl, fallbackUrl, params) {
  if (resizableUrl) {
    const urlObj = new URL(resizableUrl);

    Object.entries(params ?? {}).forEach(([key, value]) => {
      urlObj.searchParams.append(key, value.toString());
    });

    return urlObj.toString();
  }

  return fallbackUrl;
}

export const mapDbsDepositsToObs = (data) => {
  if (!data || !data.entries?.length) {
    return [];
  }

  return data.entries
    .filter(
      (entry) =>
        // remove revoked orders from the UI
        !['AUTO_REVOKED', 'REVOKED'].includes(
          String(entry.deposit?.legacy_deposit_state).toUpperCase(),
        ),
    )
    .map((item) => {
      const { product, available_actions, deposit = {} } = item;
      const {
        interest_rates: interestRates,
        deposit_taking_bank: depositTakingBank = {},
        id: offer_id = '',
        marketed_as_product_type,
      } = product || {};

      const requiredCustomerJobs = item.customer_jobs;
      const money_routing_id = 'PLAN11111111111'; // TODO: Saving plan not yet exposed by Brokerage API. Temporarily hardcode (Luke)
      const balance = 0; // Gets updated later from DAS balance.amount.denomination
      const exchange_rate = 1; // We will no longer be supporting FX deposits on BIPS. Hardcode to 1 indicates no FX.
      const ready_for_auto_prolongation = false; // Not rendered in the UI.
      const interest_amount = ''; // Gets updated later from DAS balance.amount.denomination
      const {
        deposit_orders: depositOrders,
        id: deposit_number = '',
        maturity_date,
        currency = '',
        opening_date: booking_time = '',
        legacy_deposit_state: status = '',
        product_type,
        deposit_state,
        termination_date: terminationDate,
      } = deposit;
      const placement_date = deposit.opening_date || '';

      const displayedTerminationDate = getTerminataionDate(terminationDate, maturity_date);
      const displayedPayoutDate = getPayoutDate(
        deposit_state,
        displayedTerminationDate,
        maturity_date,
      );

      const openingOrder = depositOrders.find((order) => order.order_type === 'OPENING');
      const offer_type = DBS_PRODUCT_TYPE_MAP[marketed_as_product_type || product_type] || '';
      const {
        id: partner_bank_bic = '',
        name: partner_bank_name = '',
        branch_country: branchCountry,
        shariah_compliant: partner_bank_sharia_compliant = false,
        bank_logo_url = '',
        logo_url = '',
      } = depositTakingBank || {};
      const nominal_interest_rate = parseFloat(interestRates?.interest_rate_nominal) || 0;
      const effective_interest_rate = parseFloat(interestRates?.interest_rate_effective) || 0;
      const interest_charges = parseFloat(deposit.termination_interest_rate_nominal) || 0;
      const initial_investment = {
        currency,
        value: Number(openingOrder?.order_amount.value) || 0,
      };

      const term_months_count = product?.term_normalized?.months || '';
      const {
        name: partner_bank_country_name = '',
        source_tax_applicable,
        domestic_taxation = false,
        source_tax_reduction_process_type,
      } = branchCountry || {};
      const partner_bank_withholding_tax_process_type = source_tax_applicable
        ? 'AUTO'
        : 'NOT_REQUIRED';
      const parameters = getParameters(item);

      const product_offer = !isEmpty(product) && getProductOffer(product, available_actions);

      const cms_content = {
        logo: {
          id: 'logo_url',
          type: 'ATTACHMENT',
          media_type: 'image/png',
          url_path: getResizedImageUrl(logo_url, bank_logo_url, { width: 88 }),
        },
      };

      const customerActions = getRequiredCustomerJobs(requiredCustomerJobs);
      const is_replatformed = true;

      return {
        is_replatformed,
        deposit_number,
        money_routing_id,
        product_type,
        deposit_state,
        offer_id,
        offer_type,
        partner_bank_bic,
        nominal_interest_rate,
        effective_interest_rate,
        interest_charges,
        interest_amount,
        payout_date: displayedPayoutDate,
        termination_date: displayedTerminationDate,
        initial_investment,
        balance,
        currency,
        booking_time,
        placement_date,
        term_months_count,
        dbs_term: product?.term,
        exchange_rate,
        status,
        ready_for_auto_prolongation,
        partner_bank_name,
        partner_bank_country_name,
        partner_bank_withholding_tax_process_type,
        partner_bank_sharia_compliant,
        parameters,
        product_offer,
        cms_content,
        domestic_taxation,
        source_tax_reduction_process_type,
        ...customerActions,
      };
    });
};

export const mergeDbsDepositsWithObs = (obsDeposits, dbsDeposits) => {
  let mergedDeposits = { ...obsDeposits };

  if (!dbsDeposits) {
    return mergedDeposits;
  }

  dbsDeposits.forEach((deposit) => {
    const { depositType, depositStatus } = getDepositTypeAndStatus(deposit);

    const depositExists = mergedDeposits[depositType]?.[depositStatus]?.deposits?.some(
      (currentDeposit) => currentDeposit.deposit_number === deposit.deposit_number,
    );

    if (depositExists) {
      mergedDeposits = {
        ...mergedDeposits,
        [depositType]: {
          ...mergedDeposits[depositType],
          [depositStatus]: {
            ...mergedDeposits[depositType]?.[depositStatus],
            deposits: mergedDeposits[depositType]?.[depositStatus]?.deposits?.filter(
              (currentDeposit) => currentDeposit.deposit_number !== deposit.deposit_number,
            ),
          },
        },
      };
    }

    mergedDeposits = {
      ...mergedDeposits,
      [depositType]: {
        ...mergedDeposits[depositType],
        [depositStatus]: {
          ...mergedDeposits[depositType]?.[depositStatus],
          deposits: [...(mergedDeposits[depositType]?.[depositStatus]?.deposits ?? []), deposit],
        },
      },
    };
  });

  return mergedDeposits;
};

export const getDepositBalances = (deposits) =>
  deposits?.map((deposit) => ({
    deposit_id: deposit.deposit_id,
    balance: deposit.balance.amount.denomination,
    currency: deposit.balance.amount.currency,
    das_interest_rate: deposit.interest_rate,
    interest_amount: deposit.total_booked_interest_amount.denomination,
    initial_investment: { ...deposit.initial_investment },
  }));

export const getHistoricalDepositsBalanceFromDas = (
  updatedDeposit,
  depositStatusGroup,
  matchingDeposit,
) => {
  const initialInvestment = { ...updatedDeposit.initial_investment };

  if (depositStatusGroup === 'historical_deposits') {
    initialInvestment.value = parseFloat(matchingDeposit.initial_investment.denomination);
  }

  return initialInvestment;
};

export const updateDepositsBalanceFromDAS = (obsDeposits, dasDeposits) => {
  const updatedObsDeposits = { ...obsDeposits };
  const depositTypeKeys = Object.keys(obsDeposits).filter((key) => key !== 'ip_accounts');

  depositTypeKeys.forEach((depositTypeKey) => {
    const depositSubTypes = obsDeposits[depositTypeKey];
    const depositStatus = Object.keys(depositSubTypes).filter((key) => key !== 'notices');

    depositStatus.forEach((status) => {
      const depositAmounts = {};

      updatedObsDeposits[depositTypeKey][status].deposits = obsDeposits[depositTypeKey][
        status
      ].deposits.map((currentDeposit) => {
        const updatedDeposit = { ...currentDeposit };
        const { depositStatus: depositStatusGroup } = getDepositTypeAndStatus(updatedDeposit);

        const matchingDeposit = dasDeposits.find(
          (d) => d.deposit_id === updatedDeposit.deposit_number,
        );

        if (updatedDeposit.balance === 0 && depositStatusGroup === 'pending_deposits') {
          const balance = updatedDeposit.initial_investment.value;

          updatedDeposit.balance = parseFloat(balance);
          updatedDeposit.parameters.state_sensitive_balance = parseFloat(balance);

          depositAmounts[updatedDeposit.currency] =
            (depositAmounts[updatedDeposit.currency] || 0) + parseFloat(balance);
        } else if (matchingDeposit) {
          updatedDeposit.initial_investment = getHistoricalDepositsBalanceFromDas(
            updatedDeposit,
            depositStatusGroup,
            matchingDeposit,
          );

          updatedDeposit.use_das_rate = shouldUseDasRate(
            updatedDeposit?.deposit_state,
            updatedDeposit?.product_type,
          );
          updatedDeposit.interest_amount = matchingDeposit.interest_amount;
          updatedDeposit.balance = parseFloat(matchingDeposit.balance);
          updatedDeposit.parameters.state_sensitive_balance = parseFloat(matchingDeposit.balance);

          if (updatedDeposit?.product_offer?.product_offer_rates) {
            updatedDeposit.product_offer.product_offer_rates.das_interest_rate =
              matchingDeposit.das_interest_rate
                ? parseFloat(matchingDeposit.das_interest_rate)
                : undefined;
          }
          depositAmounts[updatedDeposit.currency] =
            (depositAmounts[updatedDeposit.currency] || 0) + parseFloat(updatedDeposit.balance);
        } else {
          depositAmounts[updatedDeposit.currency] =
            (depositAmounts[updatedDeposit.currency] || 0) + parseFloat(updatedDeposit.balance);
        }

        return updatedDeposit;
      });

      updatedObsDeposits[depositTypeKey][status].deposited_amounts = depositAmounts;
    });
  });

  return updatedObsDeposits;
};

const dbsAccessKeyMapping = {
  overnight_account: ['HAS_OVERNIGHT_DEPOSIT_ACCOUNT'],
  notice_account: ['HAS_NOTICE_DEPOSIT_ACCOUNT'],
  term_deposit: ['HAS_FIXED_DEPOSIT_ACCOUNT', 'HAS_FLEX_DEPOSIT_ACCOUNT'],
};

export const getProductAccessFromDbs = (dbsAccess) => {
  return Object.entries(dbsAccess || {})
    .filter(([i]) => !!dbsAccess[i])
    .flatMap(([key]) => dbsAccessKeyMapping[key]);
};

export const normalizeObsDeposits = (storeDeposits, fetchedDeposits) => {
  const dbsTypes = Object.values(dbsTypeToObsType);

  return dbsTypes.reduce(
    (acc, type) => {
      const currentDeposits = removeDuplicateDeposits([
        ...(acc[type]?.current_deposits?.deposits ?? []),
        ...(fetchedDeposits[type]?.current_deposits?.deposits ?? []),
      ]);
      const pendingDeposits = removeDuplicateDeposits([
        ...(acc[type]?.pending_deposits?.deposits ?? []),
        ...(fetchedDeposits[type]?.pending_deposits?.deposits ?? []),
      ]);
      const historicalDeposits = removeDuplicateDeposits([
        ...(acc[type]?.historical_deposits?.deposits ?? []),
        ...(fetchedDeposits[type]?.historical_deposits?.deposits ?? []),
      ]);

      return {
        ...acc,
        [type]: {
          ...acc[type],
          current_deposits: {
            deposits: currentDeposits,
            deposited_amounts: addDepositsAmounts(currentDeposits),
          },
          pending_deposits: {
            deposits: pendingDeposits,
            deposited_amounts: addDepositsAmounts(pendingDeposits),
          },
          historical_deposits: {
            deposits: historicalDeposits,
            deposited_amounts: addDepositsAmounts(historicalDeposits),
          },
          notices: {
            ...(acc[type]?.notices ?? {}),
            ...(fetchedDeposits[type]?.notices ?? {}),
          },
        },
      };
    },
    { ...storeDeposits },
  );
};

export const mapDbsDepositsListToObj = (data) => {
  const res = {};

  if (!data || !data.entries?.length) {
    return res;
  }

  data.entries.forEach((entry) => {
    res[entry.deposit.id] = entry;
  });

  return res;
};

/**
 * Returns which interest rate is to be used for a given Deposit.
 *
 * @param useDas
 * @param {*} dasInterestRate Interest rate coming from DAS viz. only BIPS
 * @param {*} interestRateEffective Effective Interest Rate
 * @param {*} interestRateNominal Nominal Interest Rate
 * @returns Interest Rate to be rendered under customers investments.
 */
export const getDepositInterestRateByState = (
  useDas,
  dasInterestRate,
  interestRateEffective,
  interestRateNominal,
) => {
  return useDas && dasInterestRate
    ? dasInterestRate
    : getProductInterestRateByLocale(interestRateEffective, interestRateNominal);
};

/**
 * Method to transform CAS Customer response into OBS like structure.
 *
 * @param {*} casCustomer
 * @param {*} customer OBS Customer response
 * @param {boolean} b2cDeprecateObs OBS Deprecate FF
 * @returns CAS customer data in OBS response structure.
 */
export const mapCASToOBSCustomer = (casCustomer, customer, b2cDeprecateObs = false) => {
  try {
    if (!b2cDeprecateObs) {
      return customer;
    }

    // eslint-disable-next-line no-underscore-dangle
    const persons = casCustomer?._embedded.persons || [];
    const accountHolder = persons.find(
      (person) => person.roles && person.roles.some((role) => role.name === 'ACCOUNT_HOLDER'),
    );

    if (!accountHolder) {
      return {
        first_name: '',
        last_name: '',
        display_name: '',
        is_company_customer: '',
        distributor_id: '',
        is_pending_activation: '',
        state: '',
        bac_number: '',
        default_address: {
          country: '',
        },
        email: '',
        phone_number_anonymized: '',
        referenceAccount: '',
        locale: '',
      };
    }

    const isCompanyCustomerInCAS = casCustomer.type === 'BUSINESS';

    const title =
      accountHolder.personalDetails.academicTitleCode === 'academic_title_dr' ? 'Dr. ' : '';
    const displayName = `${title}${accountHolder?.firstName} ${accountHolder?.lastName}`;
    const customerEmail = accountHolder?.contactDetails?.email;
    const customerPhoneNumber = accountHolder?.contactDetails?.phoneNumber;
    // eslint-disable-next-line no-underscore-dangle
    const customerReferenceAccount = casCustomer?._embedded?.referenceAccounts?.[0] || {};
    const isPendingActivation = [
      'REGISTERED',
      'IDENTIFIED',
      'PENDING_SERVICING_BANK_ACCEPTANCE',
    ].includes(casCustomer.status);

    return {
      ...customer, // Preserving all historic properties of OBS customer
      first_name: accountHolder?.firstName,
      last_name: accountHolder?.lastName,
      display_name: displayName,
      is_company_customer: isCompanyCustomerInCAS,
      distributor_id: casCustomer.distributorId,
      is_pending_activation: isPendingActivation,
      state: casCustomer.status,
      default_address: {
        country: casCustomer?.address?.countryCode,
      },
      email: customerEmail,
      phone_number_anonymized: customerPhoneNumber,
      referenceAccount: customerReferenceAccount,
      bac_number: getBACNumberFromJWT(),
      locale: casCustomer?.address?.countryCode,
    };
  } catch (e) {
    console.info(e);

    return {
      ...customer, // Preserving all historic properties of OBS customer
      first_name: 'Raisin',
      last_name: 'Raisin',
      display_name: 'Raisin',
    };
  }
};

export const AccountState = {
  Initiated: 'INITIATED',
  Requested: 'REQUESTED',
  Active: 'ACTIVE',
  ClosingInProgress: 'CLOSING_IN_PROGRESS',
  Closed: 'CLOSED',
  Suspended: 'SUSPENDED',
};

const isFulfilledAccount = (state) =>
  state &&
  [
    AccountState.Active,
    AccountState.Suspended,
    AccountState.Closed,
    AccountState.ClosingInProgress,
  ].includes(state);

export const findFulfilledAccountId = (response) => {
  const accounts = response?.payload?.data;
  const data = Array.isArray(accounts) ? accounts : [accounts];

  if (data?.length > 0) {
    const fulfilledAccount = data.find((acc) => isFulfilledAccount(acc?.state)) ?? data[0];

    return fulfilledAccount?.id;
  }

  return undefined;
};

export const getTransactionsAccountData = async ({
  fetchTransactionAccount,
  fetchAccountsDispatch,
  tamsTransactionAccount,
}) => {
  let fulfilledAccountId;

  if (tamsTransactionAccount?.id) {
    fulfilledAccountId = tamsTransactionAccount?.id;
  } else {
    const bacNumber = getBACNumberFromJWT();

    await fetchAccountsDispatch(bacNumber)?.then((response) => {
      fulfilledAccountId = findFulfilledAccountId(response);
    });
  }

  fulfilledAccountId && fetchTransactionAccount(fulfilledAccountId);
};

export const isCustomerOffBoarding = (status) =>
  [CustomerStatus.TerminationRequested, CustomerStatus.TerminationInitiated].includes(status);
