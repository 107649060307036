import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import * as d3 from 'd3';
import { reportError } from '../../../utils/ErrorHandling';
import { formatLocaleToUnifiedDomain } from '../../../utils/Locale';
import LocalizeProvider from '../LocalizeProvider';

export class TranslationsProvider extends Component {
  static propTypes = {
    translations: PropTypes.shape({
      de: PropTypes.func.isRequired,
      'en-US': PropTypes.func,
      'en-IE': PropTypes.func,
      'en-GB': PropTypes.func,
      fr: PropTypes.func,
      es: PropTypes.func,
      nl: PropTypes.func,
      'pl-PL': PropTypes.func,
      'fi-FI': PropTypes.func,
    }).isRequired,
    children: PropTypes.node.isRequired,
    locale: PropTypes.string.isRequired,
    id: PropTypes.string,
  };

  state = {
    messages: null,
    locale: 'de',
    /* It is used by d3 to localize dates.
      To not have to make external requests from OBS we store them
      in locale_*.json files here. You can find the content of a
      locale at unpkg.com, for example for the 'de' locale
      it is https://unpkg.com/d3-time-format@2.1.1/locale/de-DE.json
      When you want to add a new locale, create a locale_*.json file and paste
      the contents */
    d3Locales: {},
  };

  globalTranslations = {
    'de-AT': () => import('../../../__global_translations__/de.json'),
    de: () => import('../../../__global_translations__/de.json'),
    'en-GB': () => import('../../../__global_translations__/en_GB.json'),
    'en-US': () => import('../../../__global_translations__/en_US.json'),
    'en-IE': () => import('../../../__global_translations__/en_IE.json'),
    fr: () => import('../../../__global_translations__/fr.json'),
    es: () => import('../../../__global_translations__/es.json'),
    nl: () => import('../../../__global_translations__/nl.json'),
    'pl-PL': () => import('../../../__global_translations__/pl_PL.json'),
    'fi-FI': () => import('../../../__global_translations__/fi_FI.json'),
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.locale !== prevState.locale) {
      return {
        ...prevState,
        locale: nextProps.locale,
      };
    }

    return prevState;
  }

  componentDidMount() {
    const { d3Locales, locale } = this.state;
    const acceptedLocales = ['de', 'es', 'fr', 'en-GB', 'en-US', 'en-IE', 'nl', 'de-AT', 'pl-PL', 'fi-FI'];
    let parsedLocale = formatLocaleToUnifiedDomain(locale);

    parsedLocale = acceptedLocales.includes(parsedLocale) ? parsedLocale : 'en-US';

    if (!d3Locales[parsedLocale]) {
      const localeOptions = localStorage.getItem(`locale_${parsedLocale}`);

      if (localeOptions) {
        d3.timeFormatDefaultLocale(JSON.parse(localeOptions));
      } else {
        import(`./locale_${parsedLocale}.json`)
          .then((localeOptions) => {
            d3.timeFormatDefaultLocale(localeOptions);
            localStorage.setItem(`locale_${parsedLocale}`, JSON.stringify(localeOptions));
            const d3LocalesNew = {
              ...d3Locales,
              [parsedLocale]: localeOptions,
            };

            this.setState({ d3Locales: d3LocalesNew });
          })
          .catch((error) => {
            reportError(`Unable to load locale ${locale}`, error);
          });
      }
    } else {
      d3.timeFormatDefaultLocale(d3Locales[locale]);
    }

    this.getMessages().then((messages) => {
      this.setState({ messages });
    });
  }

  async componentDidUpdate(nextProps) {
    if (nextProps.locale && nextProps.locale !== this.state.locale) {
      const messages = await this.getMessages();

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ messages });
    }
  }

  mapLocale = (locale) => {
    switch (locale) {
      case 'de-AT':
        return 'de';
      case 'fr-FR':
        return 'fr';
      default:
        return 'en-US';
    }
  };

  loadMessages = async (id) => {
    const { locale } = this.props;
    const { localStorage } = window;

    const currentLocale = formatLocaleToUnifiedDomain(locale);

    // eslint-disable-next-line no-underscore-dangle
    const version = window.__frontend__version__;

    const messagesBaseKey = `translations-${id}-`;
    const messagesKey = `${messagesBaseKey}${currentLocale}-${version}`;
    let messages;

    // try getting the messages from the localStorage and return them
    if (localStorage && process.env.NODE_ENV !== 'local') {
      messages = localStorage.getItem(messagesKey);
      if (messages) {
        messages = JSON.parse(messages);

        return messages;
      }
    }

    // ... no messages found in the localStorage
    // try loading the messages from the translations files
    try {
      if (id === 'global') {
        messages = await this.globalTranslations[currentLocale]();
      } else {
        messages = await this.props.translations[currentLocale]();
      }
    } catch (e) {
      try {
        // this fails when the locale is i.e. 'de-AT' and only 'de' translations are available
        // mapLocale will make 'de-AT' fall back to 'de'
        const fallbackLocale = this.mapLocale(currentLocale);

        if (id === 'global') {
          messages = await this.globalTranslations[fallbackLocale]();
        } else {
          messages = await this.props.translations[fallbackLocale]();
        }
      } catch (error) {
        // eslint-disable-next-line no-empty
        // if an error happens while loading fallback locale there is nothing else we can do
      }
    }

    if (localStorage) {
      // clean older translations - we clean at this point because it's where we are loading new translations and the old ones become deprecated
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(messagesBaseKey)) {
          localStorage.removeItem(key);
        }
      });
      // store new messages in the localStorage
      localStorage.setItem(messagesKey, JSON.stringify(messages));
    }

    return messages;
  };

  getMessages = async () => {
    const { id } = this.props;

    const globalMessages = await this.loadMessages('global');
    const messages = await this.loadMessages(id);

    return {
      ...globalMessages,
      ...messages,
    };
  };

  renderTranslatedComponent() {
    return (
      <IntlProvider locale={this.state.locale} messages={this.state.messages}>
        <LocalizeProvider>{this.props.children}</LocalizeProvider>
      </IntlProvider>
    );
  }

  render() {
    return this.state.messages ? this.renderTranslatedComponent() : null;
  }
}

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  locale: state.ui.locale,
});

export default connect(mapStateToProps)(TranslationsProvider);
