import { getLocaleFromLocalStorage } from 'utils/Locale';

export const initialState = {
  locale: getLocaleFromLocalStorage(), // this method will default to 'de'
  notices: {},
  isPosting: false,
  isIPBonusModalOpen: false,
  isIPRiskModalOpen: false,
  isPendingUploadModalOpen: false,
  isNationalIdentifierModalOpen: false,
  taxNotificationTab: {
    investments: [
      {
        id: null,
        isOpen: false,
      },
    ],
  },
  faqSelection: {
    section: null,
    number: null,
  },
  requestError: null,
  hasIPAmountChange: false,
  orderFormValidity: {
    amount: false,
    appropriatenessTest: false,
    productExplanation: false,
    legalNotice: false,
    furtherInformation: false,
    savingsPlan: false,
  },
  hasTaxId: false,
  maxAmountInputValue: 999999999, // DB can handle up to 20 digits,
  features: {},
  areFeaturesLoaded: false,
  documents: {
    identification: {
      list: [],
      pdfBlob: '',
    },
    proof_of_address: {
      list: [],
      pdfBlob: '',
    },
  },
  isLoadingCustomerTimeline: false,
  isLoadingDocumentsTimeline: false,
  referAFriendCountries: [],
  hasCreatedInitialReferenceAccount: false,
  currentPage: {},
  hasFetchedAllWeltInvestPortfolios: false,
  hasFetchedAllJustEtfPortfolios: false,
  isBlocked: false,
};
