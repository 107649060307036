import { getUrl } from './utils';
import { getLogo } from '../utils';

export const getLogoItems = (pathname, is_company_customer, distributor_id, branding) => {
  const logoItems = [
    {
      id: 'header-logo-bar-logo-link',
      href: getUrl(`/#/dashboard`),
      url: getUrl(`/assets/img/${getLogo(pathname, is_company_customer)}`),
      alt: '',
    },
  ];

  const distributorPartnerLogo = branding?.logo;

  if (distributorPartnerLogo) {
    logoItems.push({
      id: 'header-logo-bar-partner-logo',
      href: getUrl(`/#/dashboard`),
      url: distributorPartnerLogo,
      alt: distributor_id,
    });
  }

  return logoItems;
};
