import React, { Component, Suspense } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HashRouter, Redirect, Switch } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import 'ui-lib/src/styles/styles.scss';

import { LINKS } from 'components/Layout/menu';
import RaisinRoute from 'components/RaisinRoute';
import {
  changeCurrency,
  fetchCustomerBranding,
  fetchCustomerProductAccess,
} from 'store/customer/actions';
import { fetchFeatureFlags } from 'store/global/actions';
import { fetchDBSProductsAccess } from 'store/customer/actions/dbs';
import { changePPTargetLink } from 'store/pensionProducts/actions';
import { changeLocale, changeUIState, fetchFeatureSwitches } from 'store/ui/actions';
import { isDEUCustomer, localeByCountry } from 'utils/CustomerUtils';
import { getCurrencyForLocale, getLocaleFromLocalStorage } from 'utils/Locale';
import { fetchPomProducts } from './store/investmentProducts/actions/pom';

import { redirectExternal } from './components/Header/data/utils';
import { EXTERNAL_LINKS } from './components/Header/Navigation/menuLinks';
import LoadingPlaceholder from './components/LoadingPlaceholder';
import Globals from './pages/Globals';
import { redirectProducts } from './utils/Routing';

const LoadingScreen = React.lazy(() => import('./pages/LoadingScreen'));

const SwitchMTan = React.lazy(() => import('./pages/Administration/SwitchMTan'));

const MyInvestments = React.lazy(() => import('./pages/MyInvestments'));
const Products = React.lazy(() => import('./pages/Products'));
const FormsList = React.lazy(() => import('./pages/Administration/FormsList'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const CMSPage = React.lazy(() => import('./pages/CMSPage'));
const LockMTanEdit = React.lazy(() => import('./pages/LockMTan/LockMTanEdit'));
const LockMTanConfirm = React.lazy(() => import('./pages/LockMTan/LockMTanConfirm'));
const BlockAccount = React.lazy(() => import('./pages/BlockAccount'));
const DocumentRequiredInformation = React.lazy(() =>
  import('./pages/DocumentRequired/DocumentRequiredInformation'),
);
const DocumentRequiredConfirm = React.lazy(() =>
  import('./pages/DocumentRequired/DocumentRequiredConfirm'),
);
const IPLandingConfigurator = React.lazy(() => import('./pages/IPLandingConfigurator'));
const IPConfiguratorFundsOverview = React.lazy(() => import('./pages/IPConfiguratorFundsOverview'));

class Router extends Component {
  static propTypes = {
    fetchFeatureSwitches: PropTypes.func.isRequired,
    changeLocale: PropTypes.func.isRequired,
    changeCurrency: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    features: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    areFeaturesLoaded: PropTypes.bool.isRequired,
    fetchCustomerBranding: PropTypes.func.isRequired,
    fetchProductAccess: PropTypes.func.isRequired,
    fetchPomProducts: PropTypes.func.isRequired,
    changeUIState: PropTypes.func.isRequired,
    changePPTargetLink: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    token: PropTypes.string,
    fetchFeatureFlags: PropTypes.func.isRequired,
    featureFlags: PropTypes.object.isRequired,
  };

  state = {
    allOBSDataLoaded: false,
  };

  constructor(props) {
    super(props);
    const customerLocale = getLocaleFromLocalStorage();
    const customerCurrency = getCurrencyForLocale(customerLocale);

    if (customerLocale && customerLocale !== props.locale) {
      props.changeLocale(customerLocale);
    }
    if (customerCurrency && customerCurrency !== props.customer.currency) {
      props.changeCurrency(customerCurrency);
    }
  }

  componentDidMount() {
    const { fetchFeatureFlags, fetchDbsAccess, customer } = this.props;

    fetchFeatureFlags();

    fetchDbsAccess(customer.bac_number);
  }

  componentDidUpdate() {
    const {
      customer,
      locale,
      casCustomerData,
      featureFlags,
      changeLocale: dispatchChangeLocale,
      changeCurrency: dispatchChangeCurrency,
      fetchProductAccess,
      fetchPomProducts: fetchPomProductsAccess,
      fetchFeatureSwitches: getFeatureSwitches,
      fetchCustomerBranding: getCustomerBranding,
    } = this.props;

    const { allOBSDataLoaded } = this.state;

    const hasB2cDeprecateObsFeatureFlag = featureFlags?.b2cDeprecateObs;
    const countryFromCas = casCustomerData?.address?.countryCode;
    const countryFromCasFormatted = localeByCountry[countryFromCas];

    const customerLocale = !hasB2cDeprecateObsFeatureFlag
      ? customer.locale
      : countryFromCasFormatted;

    if (!isEmpty(featureFlags) && !allOBSDataLoaded) {
      if (!hasB2cDeprecateObsFeatureFlag) {
        getFeatureSwitches();
        fetchProductAccess();
      } else if (isDEUCustomer(customer)) {
        fetchPomProductsAccess();
      }
      const { distributor_id, isLoggedIn, branding} = customer;

      if(!branding?.logo) {
        getCustomerBranding(isLoggedIn, distributor_id);
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ allOBSDataLoaded: true });
    }

    if (customerLocale && customerLocale !== locale) {
      dispatchChangeLocale(customerLocale);
      dispatchChangeCurrency(getCurrencyForLocale(customerLocale));
    }
  }

  render() {
    const { features } = this.props;

    localStorage.removeItem('productAccess');

    const canSeeIpProducts = features.ip_view_products || false;

    return (
      <HashRouter>
        <div>
          <Suspense fallback={<LoadingPlaceholder height={300} width="100%" />}>
            <Switch>
              <RaisinRoute
                exact
                path="/postbox/documents"
                component={() => {
                  redirectExternal(EXTERNAL_LINKS.DOCUMENTS.HOME);
                }}
              />
              <RaisinRoute
                exact
                path="/postbox/index"
                component={() => {
                  redirectExternal(EXTERNAL_LINKS.POSTBOX.HOME);
                }}
              />
              <RaisinRoute
                exact
                path="/postbox/compose/*"
                component={() => {
                  redirectExternal(EXTERNAL_LINKS.POSTBOX.WRITE_MESSAGE);
                }}
              />
              <Redirect
                exact
                from="/InvestmentProducts"
                to={LINKS.PRODUCTS.INVESTMENT_PRODUCTS_PARENT}
              />
              <RaisinRoute
                isAllowed={canSeeIpProducts}
                path={LINKS.PRODUCTS.INVESTMENT_PRODUCTS_ROBO}
                component={() => {
                  window.location.assign(LINKS.PRODUCTS.INVESTMENT_PRODUCTS_ROBO);
                }}
                exact
                isIPRoute
              />
              <RaisinRoute
                isAllowed={canSeeIpProducts}
                path="/investieren/robo"
                component={() => {
                  window.location.assign(LINKS.PRODUCTS.INVESTMENT_PRODUCTS_ROBO);
                }}
                exact
                isIPRoute
              />
              <RaisinRoute
                isAllowed={canSeeIpProducts}
                path="/investieren/robo-advisor"
                component={() => {
                  window.location.assign(LINKS.PRODUCTS.INVESTMENT_PRODUCTS_ROBO);
                }}
                exact
                isIPRoute
              />
              <RaisinRoute
                isAllowed={canSeeIpProducts}
                path={LINKS.PRODUCTS.INVESTMENT_PRODUCTS_PARENT}
                component={() => {
                  window.location.assign(LINKS.PRODUCTS.INVESTMENT_PRODUCTS_PARENT);
                }}
                exact
                isIPRoute
              />
              <RaisinRoute
                isAllowed={canSeeIpProducts}
                path={LINKS.PRODUCTS.INVESTMENT_PRODUCTS_CONFIGURATOR}
                component={IPLandingConfigurator}
                exact
                isIPRoute
              />
              <RaisinRoute
                isAllowed={canSeeIpProducts}
                path={LINKS.PRODUCTS.INVESTMENT_PRODUCTS_CONFIGURATOR_FUNDS_OVERVIEW}
                component={IPConfiguratorFundsOverview}
                exact
                isIPRoute
              />
              <RaisinRoute
                path="/Products(/easyAccess|/overnight|/notice)?"
                component={({ match: { url } }) =>
                  redirectProducts({
                    url,
                    Products,
                  })
                }
              />
              <RaisinRoute
                path="/Identification"
                component={() => {
                  redirectExternal(EXTERNAL_LINKS.IDENTIFICATION.HOME);
                }}
                exact
              />
              <RaisinRoute
                path="/Identification/proofOfAddress"
                component={() => {
                  redirectExternal(EXTERNAL_LINKS.IDENTIFICATION.PROOF_OF_ADDRESS);
                }}
                exact
              />
              <RaisinRoute path="/about/:section" component={CMSPage} />
              <RaisinRoute path="/Dashboard" component={Dashboard} />
              <RaisinRoute path="/Administration/FormsList" component={FormsList} />
              <RaisinRoute path="/Administration/SwitchMTan" component={SwitchMTan} />
              <RaisinRoute path="/LockMTan/edit" component={LockMTanEdit} exact />
              <RaisinRoute path="/LockMTan/confirm" component={LockMTanConfirm} exact />
              <RaisinRoute path="/BlockAccount" component={BlockAccount} exact />
              <RaisinRoute
                path="/DocumentRequired/information/:depositNumber"
                component={DocumentRequiredInformation}
                exact
              />
              <RaisinRoute
                path="/DocumentRequired/confirm"
                component={DocumentRequiredConfirm}
                exact
              />
              {/* <RaisinRoute
                isAllowed={canReferAFriend}
                path="/ReferAFriend/leads"
                component={ReferAFriendLeads}
              />
              <RaisinRoute
                isAllowed={canReferAFriend}
                path="/ReferAFriend/confirm"
                component={ReferAFriendConfirm}
              /> */}
              <RaisinRoute
                path="/MyInvestments/(TermDeposit|Overnight|Notice)"
                component={MyInvestments}
                exact
              />
              <RaisinRoute
                hideMenu
                showHeader={false}
                showFooter={false}
                path={LINKS.LOADING_SCREEN}
                component={LoadingScreen}
              />
            </Switch>
          </Suspense>
          <Globals />
        </div>
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.ui.locale,
  features: state.ui.features,
  areFeaturesLoaded: state.ui.areFeaturesLoaded,
  customer: state.customer,
  token: state.pensionProducts.token,
  raisid: state.pensionProducts.raisid,
  featureFlags: state.global.featureFlags,
  isFetchingFeatureFlags: state.global.isFetchingFeatureFlags,
  casCustomerData: state.customer.casCustomerData,
  pomProducts: state.investmentProducts.pom.pomProducts,
});

const mapDispatchToProps = {
  fetchFeatureSwitches,
  changeLocale,
  changeCurrency,
  fetchProductAccess: fetchCustomerProductAccess,
  fetchPomProducts,
  fetchCustomerBranding,
  changeUIState,
  changePPTargetLink,
  fetchFeatureFlags,
  fetchDbsAccess: fetchDBSProductsAccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
