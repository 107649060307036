import { handle } from 'redux-pack';


import {
  createCookieForSession,
  getDepositBalances,
  getProductAccessFromDbs,
  mapDbsDepositsListToObj,
  mapDbsDepositsToObs,
  mergeDbsDepositsWithObs,
  normalizeObsDeposits,
  setAutoLogoutTimestamp,
  updateDepositsBalanceFromDAS,
  mapCASToOBSCustomer,
} from 'utils/CustomerUtils';
import { isMailSubscribed } from 'utils/Newsletter';


import { cleanUpSessionData } from '../../../utils/CustomerUtils/sessionDataCleanUp';
import { ajaxStatus } from '../../../config';
import * as types from '../../actionTypes';
import { initialState } from '../selectors';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_CUSTOMER_DATA:
      return handle(state, action, {
        success: (prevState) => {
          const { headers } = payload;

          if (headers && headers['x-csrf-token']) {
            sessionStorage.setItem('x-csrf-token', headers['x-csrf-token']);
          }

          return { ...prevState, ...payload.data };
        },
      });
    case types.FETCH_CUSTOMER_INVESTMENTS:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            investments: normalizeObsDeposits(prevState.investments, payload?.data),
          };
        },
      });
    case types.DBS_FETCH_CUSTOMER_DEPOSITS: {
      const dbsDepositsWithObsStructure = mapDbsDepositsToObs(payload?.data);

      return handle(state, action, {
        success: (prevState) => {
          const mergedInvestments = mergeDbsDepositsWithObs(
            prevState.investments,
            dbsDepositsWithObsStructure,
          );

          return {
            ...prevState,
            dbsDeposits: mapDbsDepositsListToObj(payload?.data),
            investments: mergedInvestments,
          };
        },
      });
    }
    case types.DBS_MERGE_CUSTOMER_DEPOSITS_WITH_DTS: {
      return {
        ...state,
        mergedWithDTS: true,
        investments: payload,
      };
    }
    case types.FETCH_DBS_PRODUCTS_ACCESS: {
      const getProductAccess = getProductAccessFromDbs(payload?.data);

      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            productAccess: [...new Set([...prevState.productAccess, ...getProductAccess])],
          };
        },
      });
    }

    case types.FETCH_DBS_PRODUCTS_TYPES: {
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            productTypes: payload?.data,
          };
        },
      });
    }

    case types.FETCH_DDOC_DOCUMENT_REQUIREMENTS: {
      const documentRequirements = payload?.data ?? {};

      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            documentRequirements,
          };
        },
      });
    }
    case types.DAS_FETCH_CUSTOMER_DEPOSITS: {
      const balanceList = getDepositBalances(payload?.data);

      return handle(state, action, {
        finish: (prevState) => ({ ...prevState, mergedWithBIPS: true }),
        success: (prevState) => {
          const mergedInvestments = updateDepositsBalanceFromDAS(
            prevState.investments,
            balanceList,
          );

          return {
            ...prevState,
            investments: mergedInvestments,
          };
        },
        failure: (prevState) => ({ ...prevState, dasEndpointFailed: true }),
      });
    }
    case types.DAS_FETCH_DEPOSIT_TRANSACTIONS: {
      return handle(state, action, {
        success: (prevState) => {
          const { params, data } = payload || {};
          const deposit_id = params?.deposit_id;

          if (!deposit_id) return prevState;

          return {
            ...prevState,
            dasTransactions: {
              ...prevState.dasTransactions,
              [deposit_id]: data,
            },
          };
        },
      });
    }
    case types.DBS_FETCH_DEPOSIT: {
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            dbsDeposits: { ...prevState.dbsDeposit, [payload?.data?.deposit?.id]: payload?.data },
          };
        },
      });
    }

    case types.DTS_FETCH_DEPOSITS_TAX_REQUIREMENTS: {
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            dtsTaxRequirements: payload?.data?.requirements,
          };
        },
      });
    }

    case types.FETCH_CUSTOMER_DEPOSIT:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            deposit: payload.data,
          };
        },
      });
    case types.FETCH_DEPOSIT_REVOKE_DATA:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          revoke: payload.data,
        }),
      });
    case types.FETCH_CUSTOMER_VALIDITY_STATUS:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          validity: payload.data,
        }),
      });
    case types.POST_CUSTOMER_VALIDITY_STATUS:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          businessCustomerValidityPost: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          businessCustomerValidityPost: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          businessCustomerValidityPost: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.RESET_DEPOSIT_REVOKE_DATA:
      return {
        ...state,
        revoke: initialState.revoke,
        depositAccountOrderRevokeForm: initialState.depositAccountOrderRevokeForm,
      };
    case types.POST_DEPOSIT_REVOKE_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          depositAccountOrderRevokeForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          depositAccountOrderRevokeForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          revoke: payload.data,
          depositAccountOrderRevokeForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.FETCH_PORTFOLIOS:
      return handle(state, action, {
        success: (prevState) => {
          const portfolios = Object.values(payload);
          const isEligible = portfolios.some(
            (p) => p.customer_eligible_for_product_order.customer_eligible_for_product_order,
          );

          return { ...prevState, isEligibleForInvestmentProducts: isEligible };
        },
      });
    case types.FETCH_PORTFOLIO:
      return handle(state, action, {
        success: (prevState) => {
          const isEligible =
            payload.data.customer_eligible_for_product_order.customer_eligible_for_product_order;

          return { ...prevState, isEligibleForInvestmentProducts: isEligible };
        },
      });
    case types.SET_CUSTOMER_DATA:
      return { ...state, ...payload };

    case types.SET_LOGIN_ERROR:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          loginForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            loginForm: {
              status: ajaxStatus.success,
              error: null,
            },
          };
        },
        failure: (prevState) => {
          return {
            ...prevState,
            loginForm: {
              status: ajaxStatus.failure,
              error: null,
            },
          };
        },
      });

    case types.FETCH_AUTH_CONFIGURATION:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          loginForm: {
            status: ajaxStatus.posting,
            error: null,
          },
          isLoggedIn: false,
        }),
        success: (prevState) => {
          return {
            ...prevState,
            authConfiguration: payload.data,
          };
        },
        failure: (prevState) => {
          return {
            ...prevState,
            authConfiguration: {},
          };
        },
      });
    case types.FETCH_COGNITO_CREDENTIALS:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          loginForm: {
            status: ajaxStatus.posting,
            error: null,
          },
          isLoggedIn: false,
        }),
        failure: (prevState) => {
          return {
            ...prevState,
            loginForm: {
              status: ajaxStatus.failure,
            },
            isLoggedIn: false,
          };
        },
        success: (prevState) => prevState,
      });
    case types.FETCH_KEYCLOAK_CREDENTIALS:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          loginForm: {
            status: ajaxStatus.posting,
            error: null,
          },
          isLoggedIn: false,
        }),
        failure: (prevState) => {
          return {
            ...prevState,
            loginForm: {
              status: ajaxStatus.failure,
              error: {
                code: payload.cause.code,
                message: payload.message,
              },
            },
            isLoggedIn: false,
          };
        },
        success: (prevState) => prevState,
      });
    case types.TOGGLE_LOGIN_OTP_VERIFICATION:
      return {
        ...state,
        loginForm: {
          ...state.loginForm,
          ...action.payload,
        },
      };
    case types.POST_CUSTOMER_LOGIN_CREDENTIALS:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          loginForm: {
            status: ajaxStatus.posting,
            error: null,
          },
          isLoggedIn: false,
        }),
        failure: (prevState) => {
          cleanUpSessionData();

          return {
            ...prevState,
            loginForm: {
              status: ajaxStatus.failure,
              error: payload,
            },
            isLoggedIn: false,
          };
        },
        success: (prevState) => {
          createCookieForSession();
          setAutoLogoutTimestamp();

          return {
            ...prevState,
            ...payload.data.customer,
            login: {
              username: '',
              password: '',
            },
            loginForm: {
              status: ajaxStatus.success,
              error: null,
            },
            isLoggedIn: true,
          };
        },
      });
    case types.POST_CUSTOMER_PASSWORD:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          passwordForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          passwordForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          passwordForm: {
            status: ajaxStatus.success,
            error: null,
          },
          session: {
            nonce: payload.data.signature_challenge.nonce,
          },
        }),
      });

    case types.POST_LOGOUT:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          hasJustLoggedOut: true,
        }),
        failure: (prevState) => ({
          ...prevState,
          isLoggedIn: false,
          hasJustLoggedOut: true,
        }),
        success: () => ({
          ...initialState,
          isLoggedIn: false,
          hasJustLoggedOut: true,
        }),
      });
    case types.FETCH_ACCOUNTS:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            tamsError: { isMaintenance: payload.status === 206 },
          };
        },
        failure: (prevState) => {
          return {
            ...prevState,
            tamsError: { error: payload, isMaintenance: payload.status === 503 },
          };
        },
      });
    case types.FETCH_TAMS_TRANSACTION_ACCOUNT:
      return handle(state, action, {
        success: (prevState) => {
          if (!payload.data.iban) {
            payload.data.iban = '';
          }

          return {
            ...prevState,
            tamsTransactionAccount: payload.data,
            tamsError: { isMaintenance: payload.status === 206 },
          };
        },
        failure: (prevState) => {
          return {
            ...prevState,
            tamsError: { error: payload, isMaintenance: payload.status === 503 },
          };
        },
      });
    case types.FETCH_TRANSACTION_ACCOUNT_TRANSACTIONS:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            transactionAccount: { ...prevState.transactionAccount, transactions: payload.data },
          };
        },
      });
    case types.FETCH_MAILBOX_DATA:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          mailbox: {
            ...prevState.mailbox,
            all_data: payload.data.reduce((acc, el) => {
              acc[el.name] = el.messages.items;

              return acc;
            }, {}),
          },
        }),
      });
    case types.FETCH_MAILBOX_INBOX_DATA:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          mailbox: {
            ...prevState.mailbox,
            inbox: {
              ...payload.data,
            },
          },
        }),
      });
    case types.FETCH_MAILBOX_SENT_DATA:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          mailbox: {
            ...prevState.mailbox,
            sent: {
              ...payload.data,
            },
          },
        }),
      });
    case types.FETCH_MAILBOX_CURRENT_MESSAGE_DATA:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          mailbox: {
            ...prevState.mailbox,
            current_message: {
              ...payload.data,
            },
          },
        }),
      });
    case types.FETCH_MAILBOX_DOCUMENTS_DATA:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          mailbox: {
            ...prevState.mailbox,
            documents: {
              ...payload.data,
            },
          },
        }),
      });
    case types.POST_MAILBOX_DATA:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          mailDataForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          mailDataForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          mailDataForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.FETCH_MAILBOX_UNREAD_MESSAGES_COUNT:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          mailbox: {
            ...prevState.unreadMessagesCount,
            unreadMessagesCount: payload.data,
          },
        }),
      });
    case types.CHANGE_CURRENCY:
      return {
        ...state,
        currency: payload,
      };
    case types.POST_DISABLE_AUTO_PROLONGATION: {
      return handle(state, action, {
        success: (prevState) => {
          // when we disable the autoProlongation we get back the updated deposit object
          // and we need to replace it inside the deeply nested investments object
          const { deposits } = prevState.investments.term_deposits.current_deposits;
          const updatedDeposits = deposits.map((deposit) =>
            deposit.deposit_number === payload.data.deposit_number ? payload.data : deposit,
          );

          return {
            ...prevState,
            investments: {
              ...prevState.investments,
              term_deposits: {
                ...prevState.investments.term_deposits,
                current_deposits: {
                  ...prevState.investments.term_deposits.current_deposits,
                  deposits: updatedDeposits,
                },
              },
            },
          };
        },
      });
    }
    case types.POST_DISABLE_AUTO_RENEWAL: {
      return handle(state, action, {
        success: (prevState) => {
          // when we disable the auto renewal we get back the order_id and deposit_id
          // and we need to update the deeply nested deposit object with this id.
          const dbsDeposit = prevState.dbsDeposits?.[payload?.data?.deposit_id];

          const updatedDeposit = {
            ...dbsDeposit,
            deposit: {
              ...dbsDeposit.deposit,
              renewal: { ...dbsDeposit.deposit.renewal, renewal_product_id: null },
            },
          };

          return {
            ...prevState,
            dbsDeposits: { ...prevState.dbsDeposit, [payload?.data?.deposit_id]: updatedDeposit },
          };
        },
      });
    }
    case types.FETCH_IDENTIFICATION_INFO:
      return handle(state, action, {
        start: (prevState) => ({ ...prevState, isPosting: true }),
        success: (prevState) => ({ ...prevState, isPosting: false, identification: payload.data }),
      });
    case types.POST_IDNOW_SEND_SMS:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          idnowSms: {
            ...prevState.idnowSms,
            status: ajaxStatus.notPosted,
            mobileNumber: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          idnowSms: {
            ...prevState.idnowSms,
            status: ajaxStatus.failure,
            mobileNumber: payload.data.mobile_number,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          idnowSms: {
            ...prevState.idnowSms,
            status: ajaxStatus.success,
            mobileNumber: payload.data.mobile_number,
          },
        }),
      });
    case types.POST_REFERENCE_ACCOUNT_INITIAL:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          referenceAccountInitialForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          referenceAccountInitialForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          referenceAccount: {
            ...payload.data,
          },
          referenceAccountInitialForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.POST_CUSTOMER_MTAN_BLOCK:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          customerMTanBlockForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          customerMTanBlockForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          customerMTanBlockForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.FETCH_NATIONAL_IDENTIFIER:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          nationalIdentifiers: payload.data.national_identifiers.sort((a, b) => a.rank - b.rank),
        }),
      });

    case types.POST_NATIONAL_IDENTIFIER:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          nationalIdentifiersForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          nationalIdentifiersForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          nationalIdentifiers: payload.data.national_identifiers.sort((a, b) => a.rank - b.rank),
          nationalIdentifiersForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });

    case types.FETCH_CUSTOMER_PRODUCT_ACCESS:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          isFetchingProductAccess: true,
        }),
        success: (prevState) => ({
          ...prevState,
          productAccess: [...new Set([...prevState.productAccess, ...payload.data])],
        }),
        finish: (prevState) => ({
          ...prevState,
          isFetchingProductAccess: false,
        }),
      });

    case types.POM_FETCH_PRODUCTS:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          isFetchingProductAccess: true,
        }),
        success: (prevState) => ({
          ...prevState,
          pomProducts: payload?.data,
        }),
        finish: (prevState) => ({
          ...prevState,
          isFetchingProductAccess: false,
        }),
    });
    case types.FETCH_CUSTOMER_BRANDING:
      return handle(state, action, {
        success: (prevState) => ({
          ...prevState,
          branding: payload.data,
        }),
      });

    case types.POST_CUSTOMER_BLOCK_ACCOUNT:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          blockAccountForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          blockAccountForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => {
          cleanUpSessionData();

          return {
            ...prevState,
            blockAccountForm: {
              status: ajaxStatus.success,
              error: null,
            },
            isLoggedIn: false,
          };
        },
      });

    case types.POST_APPLICATION_DOCUMENT:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          applicationDocumentForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          applicationDocumentForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          applicationDocumentForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.FETCH_CUSTOMER_DATA_FOR_TOKEN:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          setPasswordForm: {
            status: ajaxStatus.posting,
            error: null,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          setPasswordForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          ...payload.data,
          setPasswordForm: {
            status: ajaxStatus.success,
            error: null,
          },
        }),
      });
    case types.POST_PASSWORD_INITIAL:
      return handle(state, action, {
        success: (prevState) => {
          createCookieForSession();

          return { ...prevState, ...payload.data, isLoggedIn: true };
        },
        failure: (prevState) => ({
          ...prevState,
          setPasswordForm: {
            status: ajaxStatus.failure,
            error: payload,
          },
        }),
      });
    case types.FETCH_MAIL_SUBSCRIPTION_DATA:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            isMailSubscribed: isMailSubscribed(action.payload.data.consents),
          };
        },
      });
    case types.SUBSCRIBE_TO_NEWSLETTER:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            isMailSubscribed: true,
          };
        },
        failure: (prevState) => ({
          ...prevState,
          isMailSubscribed: false,
        }),
      });
    case types.FETCH_CAS_CUSTOMER_DATA:
      return handle(state, action, {
        success: (prevState) => {
          const {
            payload: { params },
          } = action;

          return {
            ...prevState,
            ...mapCASToOBSCustomer(payload.data, prevState, params?.b2cDeprecateObs),
            casCustomerData: payload.data,
          };
        },
      });
    case types.FETCH_KCI_CUSTOMER_KYC_STATUS:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            kciCustomerKYCStatus: payload.data,
          };
        },
      });

    case types.FETCH_KSS_CUSTOMER_KYC_STATUS:
      return handle(state, action, {
        success: (prevState) => {
          return {
            ...prevState,
            kssCustomerKYCStatus: payload.data,
          };
        },
      });

    default:
      return state;
  }
};
