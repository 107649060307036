/* eslint-disable import/no-named-default */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'components/I18n';
import UILink from 'ui-lib/src/components/Link';
import Image from 'components/Image';
import { CREDITS, getLegalLinks } from './links';
import styles from './styles.scss';
import { trackFooterNavigation } from '../utils';

const Imprint = memo(({ country, shouldRenderTopLine }) => {
  const legalLinks = getLegalLinks()[country];

  return (
    <div className="container">
      <div className={`${styles.wrapper} ${shouldRenderTopLine ? styles.borderLine : ''}`}>
        <div className={styles.container}>
          <nav className={styles.nav}>
            {legalLinks.map((link) => {
              if (link.id === 'footer-userCentric') {
                return (
                  <UILink
                    key={link.id}
                    href={link.url}
                    id={link.id}
                    className={styles.link}
                    onClick={(e) => {
                      e.preventDefault();
                      window.UC_UI.showSecondLayer();
                      trackFooterNavigation(link.url);
                    }}
                  >
                    <Translate id={link.text} />
                  </UILink>
                );
              }

              if (link.id === 'footer-cookie') {
                return (
                  <UILink
                    key={link.id}
                    href={link.url.external}
                    id={link.id}
                    className={styles.link}
                    onClick={() => {
                      trackFooterNavigation(link.url.external);
                    }}
                  >
                    <Translate id={link.text} />
                  </UILink>
                );
              }

              return (
                <UILink
                  key={link.id}
                  href={link.url.external}
                  id={link.id}
                  className={styles.link}
                  onClick={() => {
                    trackFooterNavigation(link.url.external);
                  }}
                >
                  <Translate id={link.text} />
                </UILink>
              );
            })}
          </nav>
          <span className={styles.copyright}>
            <Translate id="footer.imprint.copyright" values={{ year: new Date().getFullYear() }} />
          </span>
        </div>

        <div className={styles.credits}>
          {CREDITS[country].map((credit) =>
            credit.url ? (
              <UILink
                key={credit.title}
                href={credit.url}
                target="_blank"
                id={credit.id}
                data-testid="credits"
              >
                <Image
                  src={credit.logo}
                  alt={credit.title}
                  className={styles.svgs}
                  loading="lazy"
                />
              </UILink>
            ) : (
              <Image
                key={credit.title}
                src={credit.logo}
                alt={credit.title}
                className={styles.svgs}
                loading="lazy"
              />
            ),
          )}
        </div>
      </div>
    </div>
  );
});

Imprint.propTypes = {
  country: PropTypes.string.isRequired,
  shouldRenderTopLine: PropTypes.bool.isRequired,
};

export default Imprint;
