import { postLogout } from 'store/global/actions';
import { trackEvent } from '@raisin/events-tracking';
import { cleanUpSessionData } from '../../../utils/CustomerUtils/sessionDataCleanUp';
import { EVENTS } from '../../../eventsTracking';
import { getTranslation } from './utils';
import { goToDeProdPensionLogoutPage, goToLoginPage, isPensionLogoutNeeded } from '../../../utils/Routing';
import { getKeycloakAuth } from '../../../pages/SetPassword/utils';

const handleLogOut = async ({
  userManagementPensionLogout,
  userManagementCallObsLogout,
}) => {
  try {
    const keycloakAuth = getKeycloakAuth();

    await keycloakAuth.keycloakLogout();

    cleanUpSessionData();

    if (userManagementCallObsLogout) {
      await postLogout();
    }



    if (userManagementPensionLogout && isPensionLogoutNeeded()) {
      goToDeProdPensionLogoutPage();
    } else {
      goToLoginPage();
    }

    trackEvent(
      {
        ...EVENTS.LOGOUT_SUCCEEDED,
      },
      true,
    );
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getActionItems = ({
  userManagementPensionLogout,
  userManagementCallObsLogout,
}) => {
  return [
    {
      id: 'logout-button',
      type: 'text',
      title: getTranslation('header.logoBar.button.logout'),
      href: '',
      callback: () => {
        handleLogOut({
          userManagementPensionLogout,
          userManagementCallObsLogout,
        });
      },
    },
  ];
};
