import isEmpty from 'lodash/isEmpty';

import {
  isAUTCustomer,
  isDEUCustomer,
  isDEURegion,
  isGBRCustomer,
  isGBRRegion,
  isIRLCustomer,
  isMtanAuth,
  isRestrictedCustomer,
} from 'utils/CustomerUtils';
import { isJustEtfCustomer } from 'utils/Distributors';
import {
  hasEtfConfiguratorProducts,
  hasPPProducts,
  LINKS as PENSION_PRODUCTS_LINKS,
} from 'utils/PensionProducts';

export const HAS_FIXED_DEPOSIT_ACCESS = 'HAS_FIXED_DEPOSIT_ACCESS';
export const HAS_FIXED_DEPOSIT_ACCOUNT = 'HAS_FIXED_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCESS = 'HAS_FLEX_DEPOSIT_ACCESS';
export const HAS_NOTICE_DEPOSIT_ACCESS = 'HAS_NOTICE_DEPOSIT_ACCESS';
export const HAS_NOTICE_DEPOSIT_ACCOUNT = 'HAS_NOTICE_DEPOSIT_ACCOUNT';
export const HAS_FLEX_DEPOSIT_ACCOUNT = 'HAS_FLEX_DEPOSIT_ACCOUNT';
export const HAS_INVESTMENT_PRODUCT_ACCESS = 'HAS_INVESTMENT_PRODUCT_ACCESS';
export const HAS_INVESTMENT_PRODUCT_ACCOUNT = 'HAS_INVESTMENT_PRODUCT_ACCOUNT';
export const HAS_OVERNIGHT_DEPOSIT_ACCESS = 'HAS_OVERNIGHT_DEPOSIT_ACCESS';
export const HAS_OVERNIGHT_DEPOSIT_ACCOUNT = 'HAS_OVERNIGHT_DEPOSIT_ACCOUNT';

export const LINKS = {
  DASHBOARD: '/Dashboard',
  MY_INVESTMENTS: {
    TERM_DEPOSIT: '/MyInvestments/TermDeposit',
    OVERNIGHT: '/MyInvestments/Overnight',
    NOTICE: '/MyInvestments/Notice',
    DWM_COCKPIT: '/investments/wealth-management/dashboard',
  },
  PRODUCTS: {
    DEFAULT: '/Products',
    EASY_ACCESS: '/Products/easyAccess',
    OVERNIGHT: '/Products/overnight',
    NOTICE: '/Products/notice',
    INVESTMENT_PRODUCTS_PARENT: '/investieren',
    INVESTMENT_PRODUCTS_ROBO: '/etf-robo',
    INVESTMENT_PRODUCTS_DWM: '/vermoegensverwaltung',
    INVESTMENT_PRODUCTS_CONFIGURATOR: '/investieren/configurator',
    INVESTMENT_PRODUCTS_CRYPTO: '/crypto',
    INVESTMENT_PRODUCTS_CONFIGURATOR_FUNDS_OVERVIEW: '/investieren/configurator/fondsuebersicht',
  },
  POSTBOX: {
    INBOX: '/Postbox/inbox',
    COMPOSE: '/Postbox/Compose/new',
  },
  SETTINGS: {
    MY_DATA: '/MyData',
    TRANSACTIONS: '/Administration/Transactions',
    REMITTANCE: '/Administration/CustomerPayout/edit',
    LOCK_ACCOUNT: '/BlockAccount',
    LOCK_MTAN: '/LockMTan/edit',
    TAX_EXEMPTION: '/TaxExemption',
    SHOW_DOCUMENTS: '/Administration/FormsList',
    SWITCH_MTAN_METHOD: '/Administration/SwitchMTan',
  },
  REFER_A_FRIEND: '/ReferAFriend/leads',
  SET_PASSWORD: '/SetPassword',
  LOGIN: '/Login',
  INVESTMENT_PRODUCTS: {
    SAVINGS_PLAN: '/InvestmentProducts/savingsPlan/edit',
  },
  ABOUT: {
    PRIVACY: 'about/privacy',
  },
  IDENTIFICATION: '/Identification',
  LOADING_SCREEN: '/Loading',
  LOGOUT: '/Logout',
};

export const getDepositAccountTypeCount = (productAccess) =>
  (productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ? 1 : 0) +
  (productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT) ? 1 : 0) +
  (productAccess.includes(HAS_NOTICE_DEPOSIT_ACCOUNT) ? 1 : 0) +
  (productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) ? 1 : 0);

export const getAvailableDepositProductsTypeCount = (productAccess) =>
  (productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS) ? 1 : 0) +
  (productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS) ? 1 : 0) +
  (productAccess.includes(HAS_NOTICE_DEPOSIT_ACCESS) ? 1 : 0) +
  (productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS) ? 1 : 0);

export const myInvestments = {
  showDropdown: (productAccess, pensionProductsData) => {
    const hasFixedOrFlex =
      productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
      productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT);
    const hasOvernight = productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT);
    const hasNotice = productAccess.includes(HAS_NOTICE_DEPOSIT_ACCOUNT);
    const hasIPPortfolio = productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCOUNT);
    const hasPPPortfolio = hasPPProducts(pensionProductsData);
    const hasETFC = hasEtfConfiguratorProducts(pensionProductsData);
    const orderedProductsCount =
      (hasFixedOrFlex ? 1 : 0) +
      (hasOvernight ? 1 : 0) +
      (hasNotice ? 1 : 0) +
      (hasIPPortfolio ? 1 : 0) +
      (hasPPPortfolio ? 1 : 0) +
      (hasETFC ? 1 : 0);

    return orderedProductsCount > 1;
  },

  showDefaultOnly: (productAccess, pensionProductsData) =>
    getDepositAccountTypeCount(productAccess) === 0 &&
    !productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCOUNT) &&
    isEmpty(pensionProductsData.products),

  showFixedFlexOnly: (productAccess, pensionProductsData) => {
    return (
      productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
      (productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT) &&
        !productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) &&
        !productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCOUNT) &&
        !productAccess.includes(HAS_NOTICE_DEPOSIT_ACCOUNT) &&
        isEmpty(pensionProductsData.products))
    );
  },

  showOvernightOnly: (productAccess, pensionProductsData) => {
    return (
      !productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) &&
      !productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT) &&
      productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) &&
      !productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCOUNT) &&
      !productAccess.includes(HAS_NOTICE_DEPOSIT_ACCOUNT) &&
      isEmpty(pensionProductsData.products)
    );
  },

  showNoticeAccountOnly: (productAccess, pensionProductsData) => {
    return (
      productAccess.includes(HAS_NOTICE_DEPOSIT_ACCOUNT) &&
      !productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) &&
      !productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT) &&
      !productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT) &&
      !productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCOUNT) &&
      isEmpty(pensionProductsData.products)
    );
  },

  showInvestmentProductsOnly: (productAccess, pensionProductsData) =>
    getDepositAccountTypeCount(productAccess) === 0 &&
    productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCOUNT) &&
    isEmpty(pensionProductsData.products),

  showETFCOnly: (productAccess, pensionProductsData) =>
    getDepositAccountTypeCount(productAccess) === 0 &&
    !productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCOUNT) &&
    !hasPPProducts(pensionProductsData) &&
    hasEtfConfiguratorProducts(pensionProductsData),

  showPensionProductsOnly: (productAccess, pensionProductsData) =>
    getDepositAccountTypeCount(productAccess) === 0 &&
    !productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCOUNT) &&
    !hasEtfConfiguratorProducts(pensionProductsData) &&
    hasPPProducts(pensionProductsData),
};

export const products = {
  showDefaultOnly: (productAccess, features) =>
    getAvailableDepositProductsTypeCount(productAccess) === 1 &&
    !productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS) &&
    !features.pp_fairr_lead_out,

  showDropdown: (productAccess, features) =>
    getAvailableDepositProductsTypeCount(productAccess) > 1 ||
    productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS) ||
    !!features.pp_fairr_lead_out,
};

export const showJustEtf = (productAccess, customer) =>
  productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS) && isJustEtfCustomer(customer);

export const getMenu = (customer, features, pensionProductsData) => {
  const { productAccess } = customer;

  return [
    {
      label: 'dashboard',
      getLink: () => LINKS.DASHBOARD,
      showFn: () => true,
    },
    {
      label: 'myInvestments',
      showFn: () =>
        isDEURegion(customer) && !myInvestments.showDropdown(productAccess, pensionProductsData),
      getLink: () => {
        if (myInvestments.showDefaultOnly(productAccess, pensionProductsData)) {
          return LINKS.MY_INVESTMENTS.TERM_DEPOSIT;
        }
        if (myInvestments.showFixedFlexOnly(productAccess, pensionProductsData)) {
          return LINKS.MY_INVESTMENTS.TERM_DEPOSIT;
        }
        if (myInvestments.showOvernightOnly(productAccess, pensionProductsData)) {
          return LINKS.MY_INVESTMENTS.OVERNIGHT;
        }
        if (myInvestments.showNoticeAccountOnly(productAccess, pensionProductsData)) {
          return LINKS.MY_INVESTMENTS.NOTICE;
        }
        if (myInvestments.showInvestmentProductsOnly(productAccess, pensionProductsData)) {
          return LINKS.MY_INVESTMENTS.DWM_COCKPIT;
        }
        if (myInvestments.showPensionProductsOnly(productAccess, pensionProductsData)) {
          return PENSION_PRODUCTS_LINKS.cockpit;
        }
        if (myInvestments.showETFCOnly(productAccess, pensionProductsData)) {
          return PENSION_PRODUCTS_LINKS.etfcCockpit;
        }

        return LINKS.MY_INVESTMENTS.TERM_DEPOSIT;
      },
    },
    {
      label: 'myInvestments',
      showFn: () =>
        isDEURegion(customer) && myInvestments.showDropdown(productAccess, pensionProductsData),
      submenu: [
        {
          label: 'fixedDeposit',
          showFn: () =>
            productAccess.includes(HAS_FIXED_DEPOSIT_ACCOUNT) ||
            productAccess.includes(HAS_FLEX_DEPOSIT_ACCOUNT),
          getLink: () => LINKS.MY_INVESTMENTS.TERM_DEPOSIT,
        },
        {
          label: 'overnightDeposit',
          showFn: () => productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCOUNT),
          getLink: () => LINKS.MY_INVESTMENTS.OVERNIGHT,
        },
        {
          label: 'noticeDeposit',
          showFn: () => productAccess.includes(HAS_NOTICE_DEPOSIT_ACCOUNT),
          getLink: () => LINKS.MY_INVESTMENTS.NOTICE,
        },
        {
          label: 'investmentProductsRobo',
          showFn: () => productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCOUNT),
          getLink: () => LINKS.MY_INVESTMENTS.DWM_COCKPIT,
        },
        {
          label: 'investmentProductsConfigurator',
          showFn: () => hasEtfConfiguratorProducts(pensionProductsData),
          getLink: () => PENSION_PRODUCTS_LINKS.etfcCockpit,
        },
        {
          label: 'pensionProducts',
          showFn: () => hasPPProducts(pensionProductsData),
          getLink: () => PENSION_PRODUCTS_LINKS.cockpit,
        },
      ],
    },
    {
      label: 'myInvestments',
      showFn: () => !isDEURegion(customer),
      getLink: () => LINKS.MY_INVESTMENTS.TERM_DEPOSIT,
    },
    {
      label: 'products',
      showFn: () =>
        (isDEURegion(customer) && products.showDefaultOnly(productAccess, features)) ||
        isRestrictedCustomer(customer) ||
        isGBRRegion(customer),
      getLink: () => LINKS.PRODUCTS.DEFAULT,
    },
    {
      label: 'products',
      hint: 'justetf.hint',
      showHintFn: () => showJustEtf(productAccess, customer),
      showFn: () =>
        (isDEUCustomer(customer) || isAUTCustomer(customer) || isIRLCustomer(customer)) &&
        products.showDropdown(productAccess, features),
      submenu: [
        {
          label: 'fixedProducts',
          showFn: () => productAccess.includes(HAS_FIXED_DEPOSIT_ACCESS),
          getLink: () => LINKS.PRODUCTS.DEFAULT,
        },
        {
          label: 'flexProducts',
          showFn: () => productAccess.includes(HAS_FLEX_DEPOSIT_ACCESS),
          getLink: () => LINKS.PRODUCTS.EASY_ACCESS,
        },
        {
          label: 'noticeProducts',
          showFn: () => productAccess.includes(HAS_NOTICE_DEPOSIT_ACCESS),
          getLink: () => LINKS.PRODUCTS.NOTICE,
        },
        {
          label: 'overnightProducts',
          showFn: () => productAccess.includes(HAS_OVERNIGHT_DEPOSIT_ACCESS),
          getLink: () => LINKS.PRODUCTS.OVERNIGHT,
        },
        {
          label: 'investmentProductsRobo',
          showFn: () => productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS),
          getLink: () => LINKS.PRODUCTS.INVESTMENT_PRODUCTS_DWM,
        },
        {
          label: 'investmentProductsConfigurator',
          showFn: () => productAccess.includes(HAS_INVESTMENT_PRODUCT_ACCESS),
          getLink: () => LINKS.PRODUCTS.INVESTMENT_PRODUCTS_CONFIGURATOR,
        },
        {
          label: 'pensionProductsOffers',
          showFn: () => features.pp_fairr_lead_out,
          getLink: () => PENSION_PRODUCTS_LINKS.general,
        },
      ],
    },
    {
      label: 'postbox',
      getLink: () => LINKS.POSTBOX.INBOX,
    },
    {
      label: 'settings',
      showFn: () => !customer.is_pending_activation,
      submenu: [
        {
          label: 'myData',
          getLink: () => LINKS.SETTINGS.MY_DATA,
        },
        {
          label: 'transactions',
          getLink: () => LINKS.SETTINGS.TRANSACTIONS,
        },
        {
          label: 'remittance',
          getLink: () => LINKS.SETTINGS.REMITTANCE,
        },
        {
          label: 'lockAccount',
          getLink: () => LINKS.SETTINGS.LOCK_ACCOUNT,
        },
        {
          label: 'lockMTan',
          getLink: () => LINKS.SETTINGS.LOCK_MTAN,
          showFn: () => isMtanAuth(customer),
        },
        {
          label: 'taxExemption',
          getLink: () => LINKS.SETTINGS.TAX_EXEMPTION,
          showFn: () => customer.has_fiduciary_account,
        },
        {
          label: 'showDocuments',
          getLink: () => LINKS.SETTINGS.SHOW_DOCUMENTS,
          showFn: () =>
            isDEUCustomer(customer) || isAUTCustomer(customer) || isGBRCustomer(customer),
        },
        {
          label: 'switchTanMethod',
          getLink: () => LINKS.SETTINGS.SWITCH_MTAN_METHOD,
          showFn: () => !isMtanAuth(customer) && features.switch_to_mtan_active,
        },
      ],
    },
    {
      label: 'referAFriend',
      hint: 'referAFriend.hint',
      getLink: () => LINKS.REFER_A_FRIEND,
      showFn: () => !customer.is_company_customer && !customer.is_pending_activation,
    },
  ];
};
