import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { LogoutCountdown, UnifiedNavigation } from '@raisin/unified-header';

import { TranslationsProvider } from 'components/I18n';
import { reportError } from 'utils/ErrorHandling';
import { getFeatureFlagClient } from 'utils/FeatureFlag';
import { isDEUCustomer } from 'utils/CustomerUtils';
import { hasSpecificPortfolio, DWM_PORTFOLIOS, ROBO_PORTFOLIOS } from 'utils/IPUtils';
import { fetchPiaProductInstances } from '../../store/investmentProducts/actions/pia';

import { fetchCASCustomerData } from '../../store/customer/actions';
import getDataSource from './data';
import styles from './styles.scss';

export const Header = ({
  customer,
  location,
  pensionProductsData,
  history,
  casCustomer,
  piaProductInstances,
  fetchPiaProductInstances,
  pomProducts,
}) => {
  const [featureFlagClient, setFeatureFlagClient] = useState();
  const [isFeatureFlagLoading, setIsFeatureFlagLoading] = useState(true);
  const [hasDWMPortfolio, setHasDWMPortfolio] = useState(false);
  const [hasMigratedRobo, setHasMigratedRobo] = useState(false);

  useEffect(() => {
    getFeatureFlagClient()
      .then((client) => {
        if (client) {
          setFeatureFlagClient(client);

          return;
        }
        reportError('Initializing LaunchDarkly client incorrectly. LD client value:', client);
      })
      .catch((err) => reportError('Failed iniatizing LaunchDarkly client.', err))
      .finally(() => {
        setIsFeatureFlagLoading(false);
      });
  }, []);

  const variation = featureFlagClient?.variation;
  const userManagementPensionLogout = variation?.('userManagementPensionLogout', false);
  const userManagementCallObsLogout = variation?.('userManagementCallObsLogout', false);
  const umtFECallKeycloakLogoutOnSessionTimeout = variation?.(
    'umtFECallKeycloakLogoutOnSessionTimeout',
    false,
  );
  const umtCallOBSLoginEndpointFromFE = variation?.('umtCallOBSLoginEndpointFromFE', false);
  const shouldShowPaymentMethodScreens = variation?.('customerShowPaymentMethodScreens', false);

  const hasB2cDeprecateObsFeatureFlag =
    !isFeatureFlagLoading && variation?.('b2cDeprecateObs', false); // True for GBR

  const ippPrivateEquityShutdown = !isFeatureFlagLoading && variation?.('ippPEIsShutDown', false);

  const { tamsTransactionAccount, tamsError } = customer;

  useEffect(() => {
    if (customer?.bac_number && customer?.isLoggedIn) {
      if (isDEUCustomer(customer)) {
        fetchPiaProductInstances(customer.bac_number);
      }
    }
  }, [customer?.bac_number, customer?.isLoggedIn]);

  useEffect(() => {
    setHasDWMPortfolio(hasSpecificPortfolio(piaProductInstances, DWM_PORTFOLIOS));
    setHasMigratedRobo(hasSpecificPortfolio(piaProductInstances, ROBO_PORTFOLIOS));
  }, [piaProductInstances]);

  const dataSource = getDataSource({
    isCustomerActive: casCustomer?.status === 'ACTIVE',
    customer,
    casCustomer,
    location,
    pensionProductsData,
    history,
    isFeatureFlagLoading,
    hasDWMPortfolio,
    hasMigratedRobo,
    tamsTransactionAccount,
    tamsError,
    userManagementPensionLogout,
    userManagementCallObsLogout,
    shouldShowPaymentMethodScreens,
    hasB2cDeprecateObsFeatureFlag,
    pomProducts,
    ippPrivateEquityShutdown,
  });
  const flags = featureFlagClient?.allFlags();

  dataSource.flags = { ...flags };

  return (
    <TranslationsProvider
      translations={{
        'en-GB': () => import('./__translations__/en-GB.json'),
        'en-IE': () => import('./__translations__/en-IE.json'),
        'en-US': () => import('./__translations__/en-US.json'),
        de: () => import('./__translations__/de.json'),
        es: () => import('./__translations__/es.json'),
        fr: () => import('./__translations__/fr.json'),
        nl: () => import('./__translations__/nl.json'),
        'pl-PL': () => import('./__translations__/pl-PL.json'),
        'fi-FI': () => import('./__translations__/fi-FI.json'),
      }}
      id="Header"
    >
      <div className={styles.navWrapper}>
        <UnifiedNavigation dataSource={dataSource} />
      </div>

      {customer?.isLoggedIn ? (
        <LogoutCountdown
          platform="raisin-frontend"
          flags={{
            userManagementCallObsLogout: flags?.userManagementCallObsLogout,
            umtFECallKeycloakLogoutOnSessionTimeout,
            umtCallOBSLoginEndpointFromFE,
          }}
        />
      ) : null}
    </TranslationsProvider>
  );
};

Header.propTypes = {
  customer: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pensionProductsData: PropTypes.shape({
    products: PropTypes.array,
    status: PropTypes.number,
  }),
  piaProductInstances: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  casCustomer: PropTypes.object,
  pomProducts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  pensionProductsData: state.dashboard.pensionProducts,
  piaProductInstances: state.investmentProducts.pia.productInstances,
  casCustomer: state.customer.casCustomerData,
  pomProducts: state.investmentProducts.pom.pomProducts,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCASCustomerData: (bacNumber, hasB2cDeprecateObsFeatureFlag) =>
    dispatch(fetchCASCustomerData(bacNumber, hasB2cDeprecateObsFeatureFlag)),
  fetchPiaProductInstances: (bacNumber) => dispatch(fetchPiaProductInstances(bacNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
